import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "before-list" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "clear" }
const _hoisted_5 = { class: "after-list" }

import { computed } from 'vue';
import VueMultiselect from 'vue-multiselect';
import CmcTitle from '../../typography/CmcTitle.vue';
import CmcText from '../../typography/CmcText.vue';
import CmcStack from '../../layout/CmcStack.vue';
import CmcGroup from '../../layout/CmcGroup.vue';
import CmcPair from '../../layout/CmcPair.vue';
import CmcIcon from '../../misc/CmcIcon.vue';
import CmcBlock from '../../layout/CmcBlock.vue';
import CmcReadOnly from '../CmcReadOnly.vue';
import CmcAlert from '../../display/CmcAlert.vue';
import CmcTag from '../../display/CmcTag.vue';
import CmcDivider from '../../misc/CmcDivider.vue';
import CmcTextInput from '../CmcTextInput.vue';
import CmcAlign from '../../layout/CmcAlign.vue';
import CmcServiceConnection from '../../display/CmcServiceConnection.vue';
import { useSelect } from './useSelect';
import { SelectOption, SingleSelectOption, GroupedSelectOption, SelectAction } from '../types';
import { isGroupedSelectOption, isSingleSelectOption } from './typeguards';

import 'vue-multiselect/dist/vue-multiselect.css';

type Value = any;

export type SelectMultiProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Label of the select.
   */
  label?: string;
  /**
   * Is the label i18n.
   */
  withLabelI18n?: boolean;

  /**
   * Description of the select.
   */
  description?: string;

  /**
   * Is the description i18n.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Model value of the select
   */
  modelValue?: any;

  /**
   * Options of the select.
   */
  options: SelectOption[];

  /**
   * Actions of the select.
   */
  actions?: SelectAction[];

  /**
   * Add a placeholder to the select
   */
  withPlaceholder?: string

  /**
   * The placeholder is an i18n label key.
   */
  withPlaceholderI18n?: boolean

  /**
   * Label to show when empty select.
   */
  withEmptyLabel?: string

  /**
   * The empty label is an i18n label key.
   */
  withEmptyLabelI18n?: boolean

  /**
   * Whether there is a custom label to display when no option is picked in a Step-based form
   */
  withReadOnlyEmptyLabel?: string;

  /**
   * Tooltip next to the label
   */
  withTooltip?: string;
  /**
   * Is the tooltip i18n.
   */
  withTooltipI18n?: boolean;

  /**
   * Error text under the select.
   */
  withErrorText?: string;

  /**
   * Error text is i18n label.
   */
  withErrorTextI18n?: boolean;

  /**
   * Set the select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Disable the select.
   */
  disabled?: boolean;

  /**
   * Select for numbers.
   */
  asNumber?: boolean;
  asOptional?: boolean;

  /**
   * Allow new options to be added that are not in the list.
   */
  allowNew?: boolean;

  /**
   * Display as tags
   */
  asTag?: boolean;

  /**
   * The maximum number of options that can be selected before we hide the remainder and don't allow more. 
   */
  maxNumSelections?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcSelectMulti',
  props: {
    id: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    modelValue: {},
    options: {},
    actions: {},
    withPlaceholder: {},
    withPlaceholderI18n: { type: Boolean },
    withEmptyLabel: {},
    withEmptyLabelI18n: { type: Boolean },
    withReadOnlyEmptyLabel: {},
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    disabled: { type: Boolean },
    asNumber: { type: Boolean },
    asOptional: { type: Boolean, default: false },
    allowNew: { type: Boolean },
    asTag: { type: Boolean },
    maxNumSelections: {}
  },
  emits: ["update:modelValue", "action"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

/** Begin 'Computed' values */
const hasGroup = computed(() => {
  return !!sectionedOptions.value.find(o => !!(o as GroupedSelectOption).options)
});

const selectedSortedOptions = computed<SingleSelectOption[] | undefined>(() => {
  if (props.modelValue === null || props.modelValue === undefined) {
    return undefined;
  }

  const selectedOptions = getSelectedOptionsFromValues(props.modelValue, optionsMap.value);
  return sortOptions(selectedOptions);
});

/** There are two cases, after we select at least one option:
 * 1. If the props.options are SingleSelectOption, we get two groups:  1. Selected, 2. The rest.
 * 2. If the props.options are GroupedSelectOption, we get two groups plus the number of given groupings:  
 * Example  ---  1. Selected, 2....X (GroupedOptions 1, GroupedOptions 2, ... , GroupedOptions X), X+1: any non-grouped, unselected options 
 */
const sectionedOptions = computed<SelectOption[]>(() => {
  const selected: SingleSelectOption[] = Array.isArray(selectedSortedOptions.value) ? selectedSortedOptions.value : [];

  const selectedValues = new Set(selected.map(s => s.value));
  if (!selected.length) {
    return props.options;
  }
  const unselectedNonGroupedGrouping: GroupedSelectOption = {
    label: '',
    options: [],
  };

  const selectedGrouping: GroupedSelectOption = {
    label: t('selected_items'),
    options: selected,
  };

  // The below has a side effect where it builds up the unselected non-grouped options.
  const unselectedGroupedOptions = maxSelectionsReached.value ? [] : [ ...props.options.map((opt: SelectOption): GroupedSelectOption | null => {
    if (isGroupedSelectOption(opt)) {
      const options: SingleSelectOption[] = opt.options.filter(c => !selectedValues.has(c.value))
      if (!options.length) {
        return null;
      }
      return {
        ...opt,
        options,
      } as GroupedSelectOption;
    } else if (!selectedValues.has(opt.value)) {
      // List of options that are currently unselected, and were passed in through props as SingleSelectOption, not GroupedSelectOption
      unselectedNonGroupedGrouping.options.push(opt);
    }
    return null;
  }).filter(opt => !!opt)];

  return [selectedGrouping, ...unselectedGroupedOptions, unselectedNonGroupedGrouping];
});

const {
  t,
  elemRef,
  search,
  opened,
  permittedActions,
  optionsMap,
  doAction,
  doFilter,
  doClose,
  onSearchBlur,
  onSearchClick,
  trackOpen,
  trackClose,
} = useSelect(props, selectedSortedOptions, emit);

const filteredOptions = computed(() => {
  return sectionedOptions.value
    .map(opt => {
      if (isGroupedSelectOption(opt)) {
        return {
          ...opt,
          options: opt.options.filter(doFilter).filter(o => o),
        }
      }
      if (isSingleSelectOption(opt) && doFilter(opt)) {
        return opt;
      }
      return null;
    }).filter(opt => opt);
});

const atLeastOneOptionHasIcon = computed<boolean>(() => {
  return sectionedOptions.value.some(opt => (opt as SingleSelectOption).hasIcon || (opt as SingleSelectOption).isServiceConnection)
});

const searchable = computed<boolean>(() => {
  return (!props.asNumber && sectionedOptions.value.length > 10 && !allNumeric()) || props.asTag;
});

const optionsValueSet = computed<Set<Value>>(() => {
  return new Set(props.options.flatMap((opt: SelectOption) => {
    if (isGroupedSelectOption(opt)) {
      return opt.options.map((o: SingleSelectOption) => o.value);
    }
    return [(opt as SingleSelectOption).value];
  }));
});

const searchValueExists = computed<boolean>(() => {
  return new Set(props.modelValue).has(search.value) || optionsValueSet.value.has(search.value);
});

const readOnlyValue = computed<string>(() => {
  if (!selectedSortedOptions.value || !Array.isArray(selectedSortedOptions.value)) {
    return '';
  }
  return selectedSortedOptions.value.map((v: SelectOption) => v.withLabelI18n ? t(v.label) : v.label).join(", ");
});

const somethingIsSelected = computed<boolean>(() => {
  return !!selectedSortedOptions.value && Array.isArray(selectedSortedOptions.value) && selectedSortedOptions.value.length > 0;
});

const maxSelectionsReached = computed<boolean>(() => {
  if (props.maxNumSelections && Array.isArray(selectedSortedOptions.value) && selectedSortedOptions.value.length >= props.maxNumSelections) {
    return true;
  }
  return false;
});

/***** 
 * End 'Computed' values
 * */

/***** 
 * Begin functions 
 * */

function sortOptions(options: SingleSelectOption[]): SingleSelectOption[] {
  return options.sort((a, b) => {
    // First sort by group label
    const groupComparison = (a.groupLabel || '').localeCompare(b.groupLabel || '');
    if (groupComparison !== 0) {
      return groupComparison;
    }
    // Then sort by option label within the same group
    return a.label.localeCompare(b.label);
  });
}

function getSelectedOptionsFromValues(values: Value[], optionsMap: Record<string, SingleSelectOption>): SingleSelectOption[] {
  return (values || []).map(value => {
    const option = optionsMap[JSON.stringify(value)] || {
      value,
      label: value,
      groupLabel: ''
    };

    return option;
  });
}

const onSelect = (opts: SelectOption[]) => {
  emit('update:modelValue', (opts as SingleSelectOption[]).map(o => o.value));
};

const doClear = () => {
  onSelect([]);
};

const allNumeric = (): boolean => {
  //@ts-ignore, JS will coerce o.label to a number and get the right answer.
  return sectionedOptions.value.every(o => (!isNaN(o.label)));
};

const onTag = (): void => {
  if (props.allowNew && search.value) {
    if (!new Set(props.modelValue).has(search.value)) {
      emit('update:modelValue', [...props.modelValue, search.value]);
      doClose();
    }
  }
};


return (_ctx: any,_cache: any) => {
  const _component_cmc_label = _resolveComponent("cmc-label")!

  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-select-${_ctx.id}` : undefined,
    label: _ctx.label,
    "with-label-i18n": _ctx.withLabelI18n,
    "model-value": readOnlyValue.value + '' || _unref(t)(_ctx.withReadOnlyEmptyLabel ? _ctx.withReadOnlyEmptyLabel : 'none'),
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        class: _normalizeClass(['cmc-select', 'cmc-select-as-multi', {
        'cmc-select-no-options': !sectionedOptions.value.length && !_ctx.disabled,
        'cmc-select-as-number': _ctx.asNumber,
        'cmc-select-as-tag': _ctx.asTag,
        'cmc-select-opened': _unref(opened),
        'cmc-select-has-value': !!selectedSortedOptions.value,
        'cmc-select-has-many-options': sectionedOptions.value.length > 1,
        'cmc-select-has-group': hasGroup.value,
      }]),
        spacing: "3xs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cmc_label, _mergeProps(props, { "as-header": "" }), null, 16),
          _createElementVNode("div", {
            class: "cmc-select-wrapper",
            onKeydown: _withKeys(onTag, ["enter"])
          }, [
            _createVNode(_unref(VueMultiselect), {
              ref_key: "elemRef",
              ref: elemRef,
              label: "label",
              options: filteredOptions.value,
              "model-value": selectedSortedOptions.value,
              placeholder: _ctx.$t('search'),
              searchable: searchable.value,
              "group-values": hasGroup.value ? 'options': undefined,
              "group-label": hasGroup.value ? 'label' : undefined,
              "track-by": "value",
              multiple: true,
              taggable: _ctx.allowNew,
              "show-labels": false,
              disabled: _ctx.disabled,
              "internal-search": false,
              closeOnSelect: false,
              onSearchChange: _cache[1] || (_cache[1] = ($event: any) => (search.value = $event)),
              "onUpdate:modelValue": onSelect,
              onOpen: _unref(trackOpen),
              onClose: _unref(trackClose)
            }, {
              beforeList: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "beforeList"),
                  (selectedSortedOptions.value && searchable.value)
                    ? (_openBlock(), _createBlock(CmcTextInput, {
                        key: 0,
                        modelValue: _unref(search),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : null)),
                        "as-search": "",
                        "inherit-read-only": false,
                        onClick: _unref(onSearchClick),
                        onBlur: _unref(onSearchBlur)
                      }, null, 8, ["modelValue", "onClick", "onBlur"]))
                    : _createCommentVNode("", true),
                  (_unref(search) && _ctx.allowNew && !searchValueExists.value)
                    ? (_openBlock(), _createBlock(CmcBlock, {
                        key: 1,
                        class: "cmc-select-press-enter"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcAlert, { text: "Press Enter to create a new tag with this name" })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              option: _withCtx(({ option }) => [
                _createVNode(CmcBlock, {
                  class: _normalizeClass(['option', !option.$isLabel ? 'cmc-select-option' : '', {'option-as-disabled': option.isDisabled} ])
                }, {
                  default: _withCtx(() => [
                    _createVNode(CmcAlign, {
                      "at-vertical-center": "",
                      "at-horizontal-center": _ctx.asNumber
                    }, {
                      default: _withCtx(() => [
                        (option.$isLabel)
                          ? (_openBlock(), _createBlock(CmcStack, {
                              key: 0,
                              class: _normalizeClass(['cmc-select-group-label', {'empty': !option.$groupLabel}]),
                              spacing: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcDivider, { class: "group-separator" }),
                                (option.$groupLabel)
                                  ? (_openBlock(), _createBlock(CmcTitle, {
                                      key: 0,
                                      title: option.$groupLabel,
                                      "with-i18n": "",
                                      heading: "h5"
                                    }, null, 8, ["title"]))
                                  : _createCommentVNode("", true),
                                (option.detail && !option.hideDetailInOptions)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 1,
                                      "with-i18n": option.withDetailI18n,
                                      text: option.withDetailI18n ? _unref(t)(option.detail, option.interpolation): option.detail,
                                      size: "m",
                                      "as-description": ""
                                    }, null, 8, ["with-i18n", "text"]))
                                  : _createCommentVNode("", true),
                                (option.withTooltip)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 2,
                                      text: "",
                                      "with-tooltip": option.withTooltip,
                                      "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                    }, null, 8, ["with-tooltip", "with-tooltip-i18n"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["class"]))
                          : (option.isServiceConnection)
                            ? (_openBlock(), _createBlock(CmcServiceConnection, {
                                key: 1,
                                name: option.label,
                                type: option.type,
                                "as-select-option": true,
                                "as-category-title": option.isCategoryTitle,
                                "with-i18n": option.withLabelI18n,
                                "with-tooltip": option.withTooltip,
                                "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                "with-tooltip-html": option.withTooltipHtml
                              }, null, 8, ["name", "type", "as-category-title", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
                            : (option.hasIcon || atLeastOneOptionHasIcon.value)
                              ? (_openBlock(), _createBlock(CmcPair, {
                                  key: 2,
                                  class: _normalizeClass(['cmc-select-option-with-icon']),
                                  "stretch-rhs": "",
                                  spacing: "3xs"
                                }, {
                                  default: _withCtx(() => [
                                    (option.hasIcon)
                                      ? (_openBlock(), _createBlock(CmcIcon, {
                                          key: 0,
                                          icon: option.icon,
                                          size: "m",
                                          svg: "",
                                          class: "cmc-select-option-with-icon-margin"
                                        }, null, 8, ["icon"]))
                                      : (atLeastOneOptionHasIcon.value)
                                        ? (_openBlock(), _createBlock(CmcIcon, {
                                            key: 1,
                                            icon: "no-image",
                                            size: "m",
                                            svg: "",
                                            class: "cmc-select-option-with-icon-margin"
                                          }))
                                        : _createCommentVNode("", true),
                                    _createVNode(CmcText, {
                                      text: option.label,
                                      "with-i18n": option.withLabelI18n,
                                      "with-tooltip": option.withTooltip,
                                      "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                      size: "l"
                                    }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                  (_ctx.asTag)
                                    ? (_openBlock(), _createBlock(CmcTag, {
                                        key: 0,
                                        value: option.value,
                                        text: option.label,
                                        "with-i18n": option.withLabelI18n,
                                        color: option.color,
                                        "with-tooltip": option.withTooltip,
                                        "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                      }, null, 8, ["value", "text", "with-i18n", "color", "with-tooltip", "with-tooltip-i18n"]))
                                    : (_openBlock(), _createBlock(CmcGroup, {
                                        key: 1,
                                        class: "cmc-multiselect__options-label-container",
                                        spacing: "none"
                                      }, {
                                        default: _withCtx(() => [
                                          (option.groupLabel)
                                            ? (_openBlock(), _createBlock(CmcBlock, {
                                                key: 0,
                                                paddingTop: "4xs",
                                                paddingRight: "3xs"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(CmcText, {
                                                    size: "m",
                                                    text: option.groupLabel,
                                                    "with-i18n": option.withLabelI18n,
                                                    "with-tooltip": option.withTooltip,
                                                    "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                                    asDescription: !!option.groupLabel
                                                  }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "asDescription"])
                                                ]),
                                                _: 2
                                              }, 1024))
                                            : _createCommentVNode("", true),
                                          _createVNode(CmcBlock, null, {
                                            default: _withCtx(() => [
                                              _createVNode(CmcText, {
                                                size: "l",
                                                text: option.label,
                                                "with-i18n": option.withLabelI18n,
                                                "with-tooltip": option.withTooltip,
                                                "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                              }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n"])
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024))
                                ], 64))
                      ]),
                      _: 2
                    }, 1032, ["at-horizontal-center"])
                  ]),
                  _: 2
                }, 1032, ["class"])
              ]),
              placeholder: _withCtx(() => [
                (_ctx.withPlaceholder && (!selectedSortedOptions.value || (Array.isArray(selectedSortedOptions.value) && !selectedSortedOptions.value.length)) && (sectionedOptions.value.length || _ctx.disabled))
                  ? (_openBlock(), _createBlock(CmcText, {
                      key: 0,
                      text: _ctx.withPlaceholder,
                      "with-i18n": _ctx.withPlaceholderI18n,
                      size: "l",
                      "as-description": ""
                    }, null, 8, ["text", "with-i18n"]))
                  : (!sectionedOptions.value.length && !_ctx.disabled)
                    ? (_openBlock(), _createBlock(CmcBlock, {
                        key: 1,
                        "padding-horizontal": "xs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcAlert, {
                            text: _ctx.withEmptyLabel || _ctx.$t('no_options'),
                            "with-i18n": _ctx.withEmptyLabelI18n,
                            color: "blue"
                          }, null, 8, ["text", "with-i18n"])
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2))
              ]),
              caret: _withCtx(() => [
                (somethingIsSelected.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", {
                          onMousedown: _withModifiers(doClear, ["stop"])
                        }, [
                          _createVNode(CmcIcon, {
                            icon: "times-bold",
                            size: "xs",
                            svg: ""
                          })
                        ], 32),
                        _createVNode(CmcIcon, {
                          class: "caret",
                          icon: "triangle-down",
                          size: "xs",
                          svg: ""
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              tag: _withCtx(({option,remove}) => [
                (_ctx.asTag)
                  ? (_openBlock(), _createBlock(CmcTag, {
                      key: 0,
                      value: option.value,
                      text: option.label,
                      "with-i18n": option.withLabelI18n,
                      "with-remove": "",
                      color: option.color,
                      onRemove: ($event: any) => (remove(option))
                    }, null, 8, ["value", "text", "with-i18n", "color", "onRemove"]))
                  : (_openBlock(), _createBlock(CmcGroup, {
                      key: 1,
                      class: "cmc-multiselect__options-label-container",
                      spacing: "none"
                    }, {
                      default: _withCtx(() => [
                        (option.groupLabel)
                          ? (_openBlock(), _createBlock(CmcBlock, {
                              key: 0,
                              paddingTop: "4xs"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcText, {
                                  size: "m",
                                  text: option.groupLabel,
                                  "with-i18n": option.withLabelI18n,
                                  "with-tooltip": option.withTooltip,
                                  "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                  asDescription: !!option.groupLabel
                                }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "asDescription"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createVNode(CmcBlock, null, {
                          default: _withCtx(() => [
                            _createVNode(CmcText, {
                              size: "l",
                              text: option.label,
                              "with-i18n": option.withLabelI18n,
                              "with-tooltip": option.withTooltip,
                              "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                            }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
              ]),
              noResult: _withCtx(() => [
                _createVNode(CmcAlert, {
                  text: 'search_results_empty',
                  "with-i18n": "",
                  "as-warning": ""
                })
              ]),
              afterList: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (maxSelectionsReached.value)
                    ? (_openBlock(), _createBlock(CmcBlock, {
                        key: 0,
                        class: "cmc-select-after-list",
                        padding: "xs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcAlert, {
                            text: 'multiselect_limit',
                            "with-i18n": ""
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(CmcBlock, {
                    class: "cmc-select-after-list",
                    "padding-horizontal": "s",
                    "padding-vertical": "2xs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcPair, { "stretch-lhs": "" }, {
                        default: _withCtx(() => [
                          _createVNode(CmcText, {
                            text: "multiselect_clear",
                            "with-i18n": "",
                            "as-clickable": somethingIsSelected.value,
                            "as-disabled": !somethingIsSelected.value,
                            size: "l",
                            onClick: doClear
                          }, null, 8, ["as-clickable", "as-disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_unref(permittedActions))
                    ? (_openBlock(), _createBlock(CmcBlock, {
                        key: 1,
                        class: "cmc-select-after-list",
                        "padding-horizontal": "s",
                        "padding-vertical": "2xs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcStack, { spacing: "4xs" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(permittedActions), (action) => {
                                return (_openBlock(), _createBlock(CmcText, {
                                  key: action.value,
                                  class: _normalizeClass(!action.isDisabled ? 'select-actions' : 'select-actions-disabled'),
                                  text: action.label,
                                  "with-tooltip": action.withTooltip,
                                  "with-i18n": "",
                                  "with-tooltip-i18n": action.withTooltipI18n ?? !!action.withTooltip,
                                  "tooltip-as-status-report": "",
                                  "as-clickable": "",
                                  size: "l",
                                  onClick: ($event: any) => (_unref(doAction)(action))
                                }, null, 8, ["class", "text", "with-tooltip", "with-tooltip-i18n", "onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 3
            }, 8, ["options", "model-value", "placeholder", "searchable", "group-values", "group-label", "taggable", "disabled", "onOpen", "onClose"]),
            (searchable.value)
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 0,
                  icon: "search",
                  class: "cmc-search-icon-lhs",
                  svg: ""
                }))
              : _createCommentVNode("", true)
          ], 32),
          (_ctx.withErrorText)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 0,
                text: _ctx.withErrorText,
                "with-i18n": _ctx.withErrorTextI18n,
                "as-error": "",
                size: "m"
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["id", "label", "with-label-i18n", "model-value", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n"]))
}
}

})