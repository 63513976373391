<template>
  <cmc-block
    :class="[`cmc-indentation-${indentationLevel}`]"
  >
    <cmc-accordion
      v-if="formElement.type === 'collapsible'"
      :text="formElement.label"
      with-I18n
      :keep-open="isErrorPresent"
    >
      <div
        v-for="fe in formElement.children"
        :key="fe.field"
      >
        <cmc-ssh-key-input
          v-if="fe.type === 'sshKey'"
          :model-value="modelValue"
          :formElement="fe"
          :as-optional="!fe.required"
          :disabled="fe.disabled || disabled"
          :errors="error"
          @reload="$emit('reload', $event)"
          @update:modelValue="inputChange"
        />
        <cmc-form-element
          v-else
          :model-value="collapsibleValue(fe.field)"
          :default-value="defaultValue[fe.field]"
          :formElement="fe"
          :disabled="fe.disabled || disabled"
          :error="collapsibleError(fe.field)"
          @update:modelValue="collapsibleValueChange(fe.field, $event)"
          @reload="$emit('reload', $event)"
          @change="$emit('change', $event)"
        />
      </div>
    </cmc-accordion>
    <cmc-block
      v-else
    >
      <cmc-sensitive-text-input
        v-if="isInput && isSensitive"
        :obscure-initial-value="!formElement.viewable"
        :model-value="modelValue?.toString()"
        :type="formElement.type"
        :label="elemLabel"
        with-label-i18n
        :with-tooltip="elemDescLabel"
        with-tooltip-i18n
        :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
        with-warning-tooltip-i18n
        :with-error-text="errorText"
        :placeholder="elemPlaceholderLabel"
        with-placeholder-i18n
        :disabled="formElement.disabled || disabled"
        @update:model-value="(v: string) => inputChange(v, false)"
        @focus="onInputFocus"
        @blur="onInputBlur"
      />
      <cmc-text-input
        v-else-if="isInput"
        :model-value="modelValue?.toString()"
        :type="formElement.type"
        :label="elemLabel"
        :as-optional="!formElement.required"
        with-label-i18n
        :with-tooltip="elemDescLabel"
        with-tooltip-i18n
        :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
        with-warning-tooltip-i18n
        :show-error-status-icon="showErrorStatusIcon"
        :with-error-text="errorText"
        :placeholder="elemPlaceholderLabel"
        with-placeholder-i18n
        :disabled="formElement.disabled || disabled"
        @update:model-value="(v: string) => inputChange(v, false)"
        @focus="onInputFocus"
        @blur="onInputBlur"
      />
      <cmc-select
        v-else-if="isSingleSelect"
        :label="elemLabel"
        :as-optional="!formElement.required"
        with-label-i18n
        :with-tooltip="elemDescLabel"
        with-tooltip-i18n
        :model-value="singleSelectValue"
        :options="selectOptions"
        :with-placeholder="formElement.disabled || disabled ? elemDisabledDescriptionLabel : elemPlaceholderLabel"
        with-placeholder-i18n
        :allow-empty="!formElement.required"
        :disabled="formElement.disabled || disabled"
        :with-error-text="errorText"
        :withEmptyLabel="formElement.emptyLabel"
        with-empty-label-i18n
        :withReadOnlyEmptyLabel="formElement.readOnlyEmptyLabel"
        @update:modelValue="inputChange"
      />
      <cmc-select-multi
        v-else-if="isMultiSelect"
        :as-tag="formElement.type === 'tags'"
        :label="elemLabel"
        :as-optional="!formElement.required"
        with-label-i18n
        :with-tooltip="elemDescLabel"
        with-tooltip-i18n
        :model-value="multiSelectValue"
        :options="selectOptions"
        :maxNumSelections="formElement.maxNumSelections"
        :with-placeholder="formElement.disabled || disabled ? elemDisabledDescriptionLabel : elemPlaceholderLabel"
        with-placeholder-i18n
        :allow-new="formElement.type === 'tags' && !formElement.options?.length"
        :disabled="formElement.disabled || disabled"
        :with-error-text="errorText"
        :withEmptyLabel="formElement.emptyLabel"
        with-empty-label-i18n
        :withReadOnlyEmptyLabel="formElement.readOnlyEmptyLabel"
        @update:modelValue="inputChange"
      />
      <cmc-checkbox-group
        v-else-if="formElement.type === 'checkboxes'"
        :model-value="checkboxGroupModelValue"
        :options="checkboxGroupOptions"
        :label="elemLabel"
        :as-optional="!formElement.required"
        with-label-i18n
        :with-tooltip="elemDescLabel"
        with-tooltip-i18n
        :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
        with-warning-tooltip-i18n
        :with-error-text="errorText"
        :disabled="formElement.disabled || disabled"
        @update:modelValue="inputChange"
      />
      <cmc-checkbox
        v-else-if="formElement.type === 'checkbox'"
        :model-value="new String(modelValue).toLowerCase() === 'true'"
        :label="elemLabel"
        with-label-i18n
        :with-tooltip="elemDescLabel"
        with-tooltip-i18n
        :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
        with-warning-tooltip-i18n
        :with-error-text="errorText"
        :disabled="formElement.disabled || disabled"
        :as-toggle="formElement.asToggle"
        :reversed="formElement.reversed"
        :as-header="formElement.asToggle"
        heading="h4"
        @update:modelValue="inputChange"
      />
      <cmc-block
        v-else-if="formElement.type === 'message'"
        padding="xs"
      >
        <cmc-alert
          :text="formElement.label"
          with-i18n
          with-full-width
          :as-warning="formElement.alertType === 'WARNING'"
          :color="alertColour"
        />
      </cmc-block>

      <cmc-sensitive-text-area
        v-else-if="isTextArea && !isCodeEditor && isSensitive"
        :obscure-initial-value="!formElement.viewable"
        :model-value="modelValue"
        :label="elemLabel"
        with-label-i18n
        :withTooltip="elemDescLabel"
        with-tooltip-i18n
        :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
        with-warning-tooltip-i18n
        :with-placeholder="elemPlaceholderLabel"
        with-placeholder-i18n
        :with-error-text="errorText"
        :disabled="formElement.disabled || disabled"
        @update:model-value="(v: string) => inputChange(v, false)"
        @focus="onInputFocus"
        @blur="onInputBlur"
      />
      <cmc-text-area
        v-else-if="isTextArea"
        :model-value="modelValue"
        :label="elemLabel"
        :as-optional="!formElement.required"
        with-label-i18n
        :withTooltip="elemDescLabel"
        with-tooltip-i18n
        :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
        with-warning-tooltip-i18n
        :with-placeholder="elemPlaceholderLabel"
        with-placeholder-i18n
        :with-error-text="errorText"
        :disabled="formElement.disabled || disabled"
        :as-code-editor="isCodeEditor"
        :language="formElement.language"
        @update:model-value="(v: string) => inputChange(v, false)"
        @focus="onInputFocus"
        @blur="onInputBlur"
      />
      <cmc-list-select-form-element
        v-else-if="isListSelect"
        :label="elemLabel"
        :with-tooltip="elemDescLabel"
        :category="category"
        :modelValue="modelValue"
        :config="selectedConfig"
        :form-element="formElement"
        :disabled="formElement.disabled || disabled"
        @update:modelValue="inputChange"
        @update:category="categoryChange"
        @update:config="configChange"
      />
      <cmc-ssh-key-input
        v-else-if="formElement.type === 'sshKey'"
        :modelValue="modelValue as Record<string, any>"
        :formElement="formElement"
        :disabled="formElement.disabled || disabled"
        :errors="error"
        @update:modelValue="inputChange"
      />
      <cmc-batch-input
        v-else-if="isBatch"
        :model-value="modelValue == null ? modelValue : modelValue.toString()"
        :form-element="formElement"
        :errors="error"
        :disabled="formElement.disabled || disabled"
        @update:model-value="(v: string) => inputChange(v, false)"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @update:batch="batchOptionChange"
      />
      <cmc-row-repeatable
        v-else-if="isRepeatableRow"
        :model-value="modelValue as RowRepeatableModelValue || []"
        :formElement="formElement.defaultFormElement"
        :errors="error || {}"
        :max-num-rows="formElement.maxNumRows"
        @change="$emit('change', $event)"
        @reload="$emit('reload', $event)"
        @update:modelValue="inputChange"
      />
      <!-- Keep this v-else in case none of the conditions are met -->
      <!-- TODO: time-select -->
      <!-- TODO: slider -->
      <!-- TODO: markdownEditor -->
      <!-- TODO: tiles -->
      <!-- TODO: repeatable -->
      <!-- TODO: composite -->
      <!-- TODO: treeselect -->
      <!-- TODO: multi-select-count -->
      <cmc-radio-group
        v-else-if="isRadio"
        :label="formElement.label"
        with-label-i18n
        :withTooltip="elemDescLabel"
        with-tooltip-i18n
        :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
        with-warning-tooltip-i18n
        :options="radioGroupOptions"
        :model-value="modelValue"
        @update:modelValue="inputChange"
      />
      <div v-else>
        TODO: {{ formElement.type }}
      </div>
    </cmc-block>
  </cmc-block>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeUnmount, onBeforeMount, PropType, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import CmcBatchInput from '@/components/nextgen/sdk/CmcBatchInput.vue';
import CmcSshKeyInput from './CmcSshKeyInput.vue';
import CmcTextArea from '../inputs/CmcTextArea.vue';
import CmcRadioGroup from '../inputs/CmcRadioGroup.vue';
import CmcRowRepeatable from '../display/CmcRowRepeatable.vue';
import { StepProviderProps } from '../form/CmcStepProvider.vue';
import CmcSelect from '../inputs/select/CmcSelect.vue';
import CmcSelectMulti from '../inputs/select/CmcSelectMulti.vue';
import { useConfirmDialog } from '../overlays/useConfirmDialog';
import { RowRepeatableModelValue } from './types';


const { t } = useI18n();
const DEBOUNCE_TIMEOUT = 800;

type FormOption = {
  interpolation: any;
  type: string;
  name: string;
  is18n?: boolean;
  disabled?: boolean;
  isLeaf?: boolean;
  children?: Object[];
  value: string;
  imageUrl?: string;
  icon?: string;
  titles?: Object[];
  options: FormOption[];
  group?: string;
  detail?: string;
  withDetailI18n?: boolean;
  color?: string;
  isDisabled?: boolean;
  tooltip?: string;
  isTooltipI18n?: boolean;
};

const props = defineProps({
  modelValue: {
    type: [Object, Date, String, Number, Boolean, Array] as PropType<object | Date | string | number | boolean | Array<any>>,
    required: false,
  },
  category: {
    type: [Object, Date, String, Number, Boolean, Array] as PropType<object | Date | string | number | boolean | Array<any>>,
    required: false,
  },
  selectedConfig: {
    type: Object,
    required: false,
  },
  formElement: {
    type: Object,
    required: true,
  },
  error: {
    type: [Array, Object] as PropType<Array<any> | object>,
  },
  /**
   * This is used to show error icons for the cases where we 
   * dont render the error text from within the form element.
   */
  showErrorStatusIcon: {
    type: Boolean,
    required: false,
  },
  disabled: {
    type: Boolean,
  },
  defaultValue: {
    type: [String, Object],
    required: false,
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void
  (e: 'update:category', value: string): void
  (e: 'changeConfig', value: object): void
  (e: 'change', value: any): void
  (e: 'reload', value: any): void
  (e: 'update:batch', value: any): void
}>();

const { open } = useConfirmDialog();
const stepProps = inject<StepProviderProps>('cmc-multi-form-step-props', null)

const debounce = ref<number | undefined>(undefined);
const valueOnFocus = ref('');

const selectOptions = computed(() => {
  const optionConv = (o: FormOption) => ({
    label: o.name,
    withLabelI18n: o.is18n,
    value: o.value,
    color: o.color ? o.color : 'light-gray',
    interpolation: o.interpolation,
    detail: o.detail,
    withDetailI18n: true,
    isDisabled: o.isDisabled,
    withTooltip: o.tooltip,
    withTooltipI18n: o.isTooltipI18n
  });
  
  return props.formElement.options.map((o: FormOption) => {
    if (o.type === 'grouped') {
      return {
        label: o.name,
        withLabelI18n: o.is18n,
        options: o.options.map(opt => optionConv(opt))
      };
    }
    return [optionConv(o)];
  }).reduce((acc: any[], g: any[]) => acc.concat(g), []);
});

const checkboxGroupModelValue = computed(() => props.modelValue == null ? props.modelValue : props.modelValue as string[]);
const checkboxGroupOptions = computed(() => props.formElement.options.map((o: FormOption) => ({
  id: o.value,
  value: o.value,
  label: o.name,
  withI18n: o.is18n,
})));

const radioGroupOptions = computed(() => props.formElement.options.map((o: FormOption) => ({
  id: o.value,
  value: o.value,
  label: o.name,
  withI18n: o.is18n,
  disabled: o.isDisabled,
  withTooltip: o.tooltip,
  withTooltipI18n: o.isTooltipI18n
})));

const alertColour = computed(() => {
  switch (props.formElement.alertType) {
    case "INFO": return 'blue';
    case "SUCCESS": return 'green';
    case "WARNING": return 'yellow';
    case "DANGER": return 'red';
  }
  return '';
});

const indentationLevel = computed(() => props.formElement?.indentation?.toLowerCase() ?? 'none' );
const isRepeatableRow = computed(() => props.formElement.type === 'repeatable-row');
const isErrorPresent = computed(() => props.error !== undefined && props.error !== null && JSON.stringify(props.error) !== '{}');
const isCollapsible = computed(() => props.formElement.type === 'collapsible');
const isBatch = computed(() => props.formElement.type === 'batch');
const isSlider = computed(() => props.formElement.type === 'slider');
const isInput = computed(() => ['text', 'password', 'number'].includes(props.formElement.type));
const isSensitive = computed(() => props.formElement.sensitive);
const isSingleSelect = computed(() => props.formElement.type === 'select');
const isMultiSelect = computed(() => props.formElement.type === 'tags' || props.formElement.type === 'multi-select-checkbox');
const isTextArea = computed(() => ['textarea', 'codeEditor'].includes(props.formElement.type));
const isCodeEditor = computed(() => props.formElement.type === 'codeEditor');
const isRadio = computed(() => props.formElement.type === 'radio');
const isListSelect = computed(() => props.formElement.type === 'listSelect' || props.formElement.type === 'listMultiSelect');

const singleSelectValue = computed(() => props.modelValue === undefined || props.modelValue === null ? props.modelValue : props.modelValue.toString());
const multiSelectValue = computed(() => (props.modelValue && typeof props.modelValue === 'string') ? props.modelValue.split(',') : props.modelValue || []);

const errorList = computed(() => {
  if (!props.error || JSON.stringify(props.error) === '{}') return [];
  const errorArray = Array.isArray(props.error) ? props.error : [props.error];
  errorArray.forEach(e => {
    if (e.context && !e.context.fieldPath) {
      e.context.fieldPath = Array.isArray(e.context.field) ? e.context.field.join('.') : e.context.field;
    }
  });
  return errorArray;
});

const elemLabel = computed(() => props.formElement.label);
const elemDescLabel = computed(() => props.formElement.descriptionLabel);
const elemDisabledDescriptionLabel = computed(() => props.formElement.disabledDescriptionLabel);
const elemPlaceholderLabel = computed(() => !props.disabled ? props.formElement.placeholderLabel : '');
const errorText = computed(() => errorList.value.map(err => t(err.context.labelKey, err.context)).join("\n"));
const askConfirmationOnValueChange = computed(() => stepProps && stepProps.highestVisitedStep > stepProps.step && stepProps.active);

function collapsibleValue(field: string) {
  if (props.modelValue && typeof props.modelValue === 'object') {
    const value = props.modelValue as Record<string, any>;
    if (field in value) return value[field];
  }
  return undefined;
}

function collapsibleError(field: string) {
  if (!props.error || JSON.stringify(props.error) === '{}') return [];
  return (props.error as Record<string, any>)[field];
}

function collapsibleValueChange(field: string, v: any) {
  const newValue = { ...props.modelValue as Record<string, any> };
  if (!v) {
    delete newValue[field];
  } else {
    newValue[field] = v;
  }
  emit('update:modelValue', newValue);
  emit('change', newValue);
}

function categoryChange(v: string) {
  emit('update:category', v);
}

function configChange(v: object) {
  emit('changeConfig', v);
  reloadSections(props.modelValue as string);
}

function inputChange(v: any, checkReload = true) {
  if (checkReload && shouldEmitReload(v)) {
    if (askConfirmationOnValueChange.value) {
      open({
        title: "plugin_operations.confirm_on_change_header",
        description: "plugin_operations.confirm_on_change_desc",
        confirmLabel: "plugin_operations.change",
        onConfirm: () => {
          emit('update:modelValue', v);
          reloadSections(v);
        },
        onCancel: () => {},
      });
    } else {
      emit('update:modelValue', v);
      reloadSections(v);
    }
  } else {
    emit('change', v);
    emit('update:modelValue', v);
  }
}

function batchOptionChange(v: Record<string, any>) {
  emit('update:batch', v);
  reloadSections({});
}

function doDebounce(func: Function, timeout = DEBOUNCE_TIMEOUT) {
  window.clearTimeout(debounce.value);
  debounce.value = window.setTimeout(() => {
    func();
    debounce.value = undefined;
  }, timeout);
}

function shouldEmitReload(value: any) {
  return props.formElement.reloadOnChange && ((value !== undefined && value !== null && value !== props.modelValue) ||
    ['checkbox', 'checkboxes', 'batch', 'listSelect', 'listMultiSelect'].includes(props.formElement.type) ||
    (props.formElement.type === 'select' && value === undefined));
}

function onInputFocus() {
  valueOnFocus.value = props.modelValue as string;
}

function onInputBlur() {
  if (!valueOnFocus.value && !props.modelValue) {
    return;
  }
  if (valueOnFocus.value !== props.modelValue && shouldEmitReload(props.modelValue)) {
    if (askConfirmationOnValueChange.value) {
      open({
        title: "plugin_operations.confirm_on_change_header",
        description: "plugin_operations.confirm_on_change_desc",
        confirmLabel: "plugin_operations.change",
        onConfirm: () => reloadSections(props.modelValue as string),
        onCancel: () => emit('update:modelValue', valueOnFocus.value),
      });
    } else {
      reloadSections(props.modelValue as string);
    }
  }
}

function reloadSections(v: any) {
  if (shouldEmitReload(v)) {
    const emitChange = () => emit('reload', {
      oldValue: props.modelValue,
      field: props.formElement.field,
      formElement: props.formElement,
      value: v,
      sections: props.formElement.sectionsToReload,
      selectedConfig: props.selectedConfig || {},
    });
    
    if ([isSlider.value, isCollapsible.value, isBatch.value].some(Boolean)) {
      doDebounce(emitChange);
    } else {
      emitChange();
    }
  }
  emit('change', v);
}

onBeforeUnmount(() => {
  if (!['message', 'confirmation', 'collapsible'].includes(props.formElement.type)) {
    emit('update:modelValue', undefined);
    emit('changeConfig', {});
    emit('update:category', '');
  }
});

onBeforeMount(() => {
  if (props.defaultValue && props.formElement.field && !['message', 'confirmation', 'collapsible'].includes(props.formElement.type)) {
    emit('update:modelValue', props.defaultValue);
  }
});
</script>
<style scoped lang="scss">
  .cmc-indentation-none {
    margin-left: 0;
  }
  .cmc-indentation-small {
    margin-left: 1.375rem;
    max-width: 22.625rem;
  }
  .cmc-indentation-medium {
    margin-left: 2rem;
    max-width: 22rem;
  }
  .cmc-indentation-large {
    margin-left: 3rem;
    max-width: 21rem;
  }
</style>