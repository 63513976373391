<template>
  <div>
    <div v-if="depth < maxDepth">
      <base-tabs
        light
        :tabs="tabValues"
        :selected="selectedTab"
        @tabChange="tabChange"
      ></base-tabs>
      <composite-form-element
        v-for="tabKey in tabKeys"
        v-show="tabKey === selectedTab"
        :key="tabKey"
        :disabled="disabled"
        :depth="depth + 1"
        :elements="tabs[tabKey]?.children || []"
        :values="values"
        :defaultValue="defaultValue"
        :error="error"
      ></composite-form-element>
    </div>
    <div v-else>
      <expandable-composite
        v-for="child in element.children"
        :key="`${child.label}${child.interpolation.name}`"
        v-model="values"
        :defaultValue="defaultValue"
        collapsible
        :disabled="disabled"
        :composite="child"
        :error="error"
      ></expandable-composite>
    </div>
  </div>
</template>
<script>
const MAX_DEPTH = 1;
export default {
  name: 'TabbedComposite',
  props: {
    element: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
    },
    error: {
      type: [Array, Object],
    },
    defaultValue: {
      type: [Object, String, Number],
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      values: this.modelValue,
      selectedTab: '',
      maxDepth: MAX_DEPTH,
    };
  },
  computed: {
    tabKeys() {
      return Object.keys(this.tabs);
    },
    tabValues() {
      return Object.values(this.tabs);
    },
    tabs() {
      return this.element.children.filter(opt => opt.type === 'composite' && opt.collapsible)
        .reduce((acc, grp) => {
          const interpolationKeys = Object.keys(grp.interpolation);
          const key = interpolationKeys.length > 0 ? `${grp.label}.${grp.interpolation[interpolationKeys[0]]}` : `${grp.label}`;
          acc[key] = grp;
          acc[key].value = key;
          return acc;
        }, {});
    },
  },
  watch: {
    values: {
      handler(updatedValues) {
        this.$emit('update:modelValue', updatedValues);
      },
      deep: true,
    },
  },
  created() {
    this.selectedTab = this.tabKeys.length > 0 ? this.tabKeys[0] : '';
  },
  methods: {
    tabChange(tab) {
      this.selectedTab = tab.value;
    },
  },
};
</script>
