import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

import { computed, reactive } from 'vue';
import CmcBlock, { CmcBlockProps } from '../layout/CmcBlock.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcText from '../typography/CmcText.vue';
import CmcFormElement from '../sdk/CmcFormElement.vue';
import { FormElement as BaseFormElement } from '@/app/Main/Services/components/models';

import { ColSize } from '../layout/types';
import { Size } from '../sizes';
import { RowError } from './types';

interface FormElement extends BaseFormElement {
  alignContent?: AlignContent;
}

type AlignContent = 'CENTER' | 'STRETCH_LHS' | 'STRETCH_RHS';

/**
 * Maps element types to their specific styling properties.
 * 
 * This dictionary allows for customized styling based on form element types:
 * - Keys: Element type identifiers (e.g., 'CHECKBOX', 'TEXT_INPUT')
 * - Values: CmcBlock styling properties to apply
 * 
 * Used in conjunction with getElementBlockProps() to merge default styles
 * with parent-provided styles. Element-specific styles take precedence
 * over default styles, allowing for targeted customization.
 * 
 * @example
 * // Define custom styles for specific element types
 * const customStyles: ElementSpecificStyles = {
 *   'CHECKBOX': { paddingTop: '2xs', marginBottom: 'md' },
 *   'SELECT': { paddingBottom: 'sm' }
 * };
 * 
 * // Pass to the component
 * <cmc-row :elementStyles="customStyles" />
 */
export type ElementSpecificStyles = {
    [elementType: string]: CmcBlockProps;
};

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * The entity
   */
  entity: Record<string, Object>;

  /**
   * The row formElement that contains the formElements to be displayed
   */
  formElement?: FormElement;

  /**
   * The errors object that contains the errors for each field in the row
   */
  errors?: Record<string, RowError[]>;

  /**
   * The padding bottom of the row
   */
  paddingBottom?: Size;
  
  /**
   * The padding top of the row
   */
  paddingTop?: Size;

  /**
  * Custom block props for specific element types
  */
  elementStyles?: ElementSpecificStyles;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcRow',
  props: {
    id: {},
    entity: {},
    formElement: {},
    errors: { default: () => ({}) },
    paddingBottom: { default: "none" },
    paddingTop: { default: "none" },
    elementStyles: {}
  },
  emits: ["change", "reload", "update:entity"],
  setup(__props: any, { emit: __emit }) {

const GRID_SIZE_MAP : Map<number, Map<string, string[]>> = new Map([
    [
        1, new Map([
        ['CENTER', ['12-12']],
        ['STRETCH_LHS', ['12-12']],
        ['STRETCH_RHS', ['12-12']]
    ])],
    [
        2, new Map([
        ['CENTER', ['6-12', '6-12']],
        ['STRETCH_LHS', ['9-12', '3-12']],
        ['STRETCH_RHS', ['3-12', '9-12']]
    ])],
    [
        3, new Map([
        ['CENTER', ['4-12', '4-12', '4-12']],
        ['STRETCH_LHS', ['6-12', '4-12', '2-12']],
        ['STRETCH_RHS', ['2-12', '4-12', '6-12']]
    ])]
    // Add more sizes here once we have the use-case.
]);

const MAX_WIDTH_MAP: Map<number, number> = new Map([
    [1, 24],
    [2, 24],
    [3, 24]
    // Add more width here once we have the use-case.
]);

/**
 * Align content of the row as returned by the SDK.
 */
const props = __props;

const emit = __emit;

// To avoid mutating the props directly, we keep a reactive reference to the props.
const entityReference = reactive(props.entity);

/**
 * Computed variable of the size of each column based on the number of elements in the row.
 */
const columnsSize = computed<ColSize[]>(() => {
    const num = props.formElement?.formElements.length ?? 1;
    const alignContent: AlignContent = props.formElement?.alignContent || 'CENTER';
    const columns = GRID_SIZE_MAP.get(num)?.get(alignContent) || ['12-12'];
    return columns as ColSize[];
});

/**
 * Computed variable of the max width (in Rem) of the row based on the number of elements in the row.
 */
const maxWidth = computed<string>(() => {
    return MAX_WIDTH_MAP.get(props.formElement?.formElements.length ?? 1) + 'rem';
});

/**
 * Default styles for different element types
 */
 const ELEMENT_TYPE_STYLES: Record<string, CmcBlockProps> = {
  'CHECKBOX': {
    paddingTop: '3xs',
  },
};

/**
 * Returns properly merged block props for a form element
 * @param element - The form element to get props for
 * @returns The merged CmcBlock props
 */
const getElementBlockProps = (element: FormElement): CmcBlockProps => {
  const elementType = element.type.toUpperCase();
  
  const elementTypeProps = ELEMENT_TYPE_STYLES[elementType] || {};
  const parentProvidedProps = props.elementStyles?.[elementType] || {};
  
  // Merge props with parent-provided props taking highest priority
  return {
    ...elementTypeProps,
    ...parentProvidedProps
  };
};

/**
 * Computed property to reorder errors based on formElements order
 */
const orderedErrors = computed(() => {
  if (!props.formElement?.formElements || !props.errors) {
    return [];
  }

  const fieldOrder = props.formElement.formElements.map((element) => element.field);

  return fieldOrder
    .map((field) => props.errors[field] || [])
    .filter((errorGroup) => errorGroup.length > 0);
});
  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, {
    "padding-top": props.paddingTop,
    "padding-bottom": props.paddingBottom
  }, {
    default: _withCtx(() => [
      _createVNode(CmcGrid, {
        style: _normalizeStyle({'max-width': maxWidth.value})
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formElement?.formElements, (child, index) => {
            return (_openBlock(), _createBlock(CmcGridCol, {
              key: index,
              u: columnsSize.value[index],
              class: _normalizeClass({ 'last-element': index === (_ctx.formElement?.formElements?.length ?? 0) - 1 })
            }, {
              default: _withCtx(() => [
                _createVNode(CmcBlock, _mergeProps({ ref_for: true }, getElementBlockProps(child)), {
                  default: _withCtx(() => [
                    _createVNode(CmcFormElement, {
                      modelValue: entityReference[child.field],
                      "onUpdate:modelValue": [($event: any) => ((entityReference[child.field]) = $event), ($event: any) => (emit('update:entity', child.field, $event))],
                      formElement: child,
                      disabled: child.disabled,
                      "show-error-status-icon": _ctx.errors[child.field]?.length > 0,
                      onChange: ($event: any) => (emit('change', child)),
                      onReload: _cache[0] || (_cache[0] = ($event: any) => (emit('reload', $event)))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "formElement", "disabled", "show-error-status-icon", "onChange"])
                  ]),
                  _: 2
                }, 1040)
              ]),
              _: 2
            }, 1032, ["u", "class"]))
          }), 128)),
          (orderedErrors.value.length > 0)
            ? (_openBlock(), _createBlock(CmcStack, {
                key: 0,
                class: "cmc-row-errors",
                spacing: "none"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderedErrors.value, (errorGroup, index) => {
                    return (_openBlock(), _createBlock(CmcText, {
                      key: index,
                      text: errorGroup[0]?.context?.labelKey ?? errorGroup[0]?.message,
                      "with-i18n": "",
                      "as-error": ""
                    }, null, 8, ["text"]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }, 8, ["padding-top", "padding-bottom"]))
}
}

})