<template>
  <div
    :key="valuesKey"
    class="base-slider"
    tabindex="0"
    @focus="forceSlider"
    @blur="forceSlider"
  >
    <vue-slider
      ref="slider"
      :modelValue="modelValue"
      :piecewise="true"
      tooltip="always"
      :data="selections"
      tooltipPlacement="bottom"
      :useKeyboard="true"
      :stepStyle="stepStyle"
      :tooltipStyle="tooltipStyle"
      :tooltipFormatter="sliderFormatter"
      :railStyle="railStyle"
      :marks="isIdealToLabel"
      @update:modelValue="input"
    >
      <template #label="{ value, active }">
        <span :class="['.vue-slider-mark-label', 'custom-label', { active }]">
          {{ roundDecimals(value) }}
        </span>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: 'BaseSlider',
  components: { VueSlider },
  props: {
    modelValue: {
      type: [ String, Number ],
      required: false,
    },
    values: {
      type: Array,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    step: {
      type: Number,
    },
    unitLabel: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      skipEmit: false,
      sliderFormatter: (v) => {
        if (this.unitLabel) {
          return `${this.roundDecimals(v)} ${this.$t(this.unitLabel)}`;
        }
        return this.roundDecimals(v);
      },
    };
  },
  computed: {
    valuesKey() {
      return this.selections.reduce((acc, cur) => `${acc}:${cur}`, "");
    },
    tooltipStyle() {
      return {
        "background-color": "#2980b9",
        "border-color": "#2980b9",
        padding: "2px 8px",
        "text-align": "center",
      };
    },
    stepStyle() {
      return {
        display: "none",
      };
    },
    railStyle() {
      return {
        height: "6px",
      };
    },
    selectionsToIndex() {
      const selectionToIndex = new Map();
      (this.selections || []).map((value, ind) => selectionToIndex.set(value, ind));
      return selectionToIndex;
    },
    selections() {
      if (this.min !== undefined && this.min !== null
        && this.max !== undefined && this.max !== null) {
        const actualStep = this.step > 0 ? this.step : 1;
        const dataLength = Math.floor((this.max - this.min) / actualStep) + 1;
        const values = [];
        for (let i = 0; i < dataLength; i += 1) {
          values.push(i * actualStep + this.min);
        }
        if (values[dataLength - 1] !== this.max) {
          values.push(this.max);
        }
        return values;
      }
      return this.values;
    },
    labelStep() {
      if (this.selections.length > 10) {
        return Math.floor(this.selections.length / 10);
      }
      return 1;
    },
  },
  created() {
    this.lastIndex = 0;
    let currValue = Number(this.modelValue);
    if (!currValue || currValue < 0) {
      if(this.values && this.values.length > 0) {
        currValue = this.values[0];
        this.$emit("update:modelValue", currValue);
      } else if (this.min !== undefined && this.min !== null) {
        this.$emit("update:modelValue", this.min);
      }
      return;
    }
    if (this.min && currValue < this.min) {
      return;
    }
    if (this.max && currValue > this.max) {
      return;
    }
    this.$emit("update:modelValue", currValue);
  },
  methods: {
    input(newVal) {
      let emitVal = newVal;
      if (emitVal === Number(this.modelValue)) {
        return;
      }
      if (this.skipEmit) {
        this.skipEmit = false;
        emitVal = Number(this.modelValue);
      }
      this.$emit("update:modelValue", emitVal);
    },
    forceSlider() {
      this.$refs.slider.$data.focusFlag = !this.$refs.slider.$data.focusFlag;
    },
    roundDecimals(num) {
      if (num !== null && num !== undefined) {
        return Math.round(num * 100) / 100;
      }
      return null;
    },
    // this helper method determines if there is an upcoming index that ought to be labelled
    // if there isn't, isIdealToLabel knows to label index even if not sufficiently round
    // otherwise, you can end up with big gaps on the slider
    existsIndexToLabel(index) {
      const toIndex = index + Math.floor(this.labelStep / 4);
      let exists = false;
      for (let i = index; i <= toIndex; i += 1) {
        if (this.isSufficientlyRound(i)) {
          exists = true;
        }
      }
      return exists;
    },
    // this helper method determines the multiples we want for our determination of roundness
    // based on max
    // returns a size 3 array
    getValidMultiples() {
      if (this.max >= 1000) {
        return [100, 50, 25];
      }
      if (this.max >= 100) {
        return [25, 10, 5];
      }
      if (this.max >= 10) {
        return [10, 5, 2];
      }
      return [1, 1, 1];
    },
    // this helper method determines if the value at a certain index is sufficiently "round"
    // that is, what a human would look at and be like, "Yup, that's a nice, round number."
    isSufficientlyRound(index) {
      const mults = this.getValidMultiples();

      if (
        this.selections[index] % mults[0] === 0 ||
        this.selections[index] % mults[1] === 0 ||
        this.selections[index] % mults[2] === 0
      ) {
        return true;
      }
      // lower our standards a bit if step size is non-round
      if (this.max >= 100 && this.step % 2 !== 0 && this.step % 5 !== 0) {
        return this.max >= 1000
          ? this.modelValue % 10 === 0 || this.modelValue % 5 === 0
          : this.modelValue % 2 === 0;
      }
      return false;
    },
    // this method returns if a given index should be labelled
    // it tries to keep labels with fairly even spacing
    // and having satisfactorily "round" numbers
    isIdealToLabel(value) {
      const index = this.selectionsToIndex.get(value);
      // min and max always get labelled
      if (index === 0 || index === (this.selections || []).length - 1) {
        this.lastIndex = index;
        return true;
      }

      // we want to find a relatively "round" number to label
      // that is near where the labelStep would naturally bring us
      // so we search the data points near the labelStep for the roundest number we can find
      // if we can't find anything nice and round, just default to labelStep
      const nextIndex = this.lastIndex + this.labelStep;
      const fromIndex = nextIndex - Math.floor(this.labelStep / 4);
      const toIndex = nextIndex + Math.floor(this.labelStep / 4);

      // check if index is close enough to labelStep so as to avoid too small or too big gaps
      // also, check if index is too close to the max index
      if (
        this.selections.length - 1 - index <
        this.labelStep - Math.floor(this.labelStep / 4)
      ) {
        return false;
      }
      if (index < fromIndex || index > toIndex) {
        return false;
      }
      if (
        this.isSufficientlyRound(index) ||
        (index === nextIndex && !this.existsIndexToLabel(index))
      ) {
        this.lastIndex = index;
        return true;
      }
      return false;
    },
  },
};
</script>


<style scoped lang="scss">
.custom-label {
  position: absolute;
  bottom: 100%;
  left: 0;
  transform: translate(-50%, -20px);
  margin-left: 3px;
}
.custom-label::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  width: 1px;
  height: 6px;
  background-color: #000;
}
.custom-label.active {
  color: #2980b9;
  font-weight: bold;
}
.custom-label.active::after {
  background-color: #2980b9;
  width: 2px;
}
.js-focus-visible .focus-visible {
  outline: none;
}
</style>
