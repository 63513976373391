<template>
  <div>
    <div
      v-for="element in groupedElements"
      :key="element.field"
    >
      <form-element
        v-if="element.type === 'row'"
        v-model="computedValues"
        :defaultValue="defaultValue"
        :formElement="element"
        :disabled="disabled"
        :error="error"
        @reload="$emit('reload', $event)"
        @change="e => computedValues = e"
        @updateRepeatableError="updateRepeatableError"
      />
      <form-element
        v-else-if="!isComposite(element)"
        v-model="computedValues[element.field]"
        :formElement="element"
        :defaultValue="defaultValue[element.field]"
        :disabled="disabled"
        :error="getErrorsForField(element.field)"
        @change="e => setFieldValue(element.field, e)"
        @reload="$emit('reload', $event)"
        @updateRepeatableError="updateRepeatableError"
      />
      <template v-else-if="isIndented(element)">
        <div
          v-if="element.label"
          class="label"
        >
          {{ $t(element.label) }}
        </div>
        <div class="indented">
          <form-element
            v-for="child in element.children"
            :key="child.field"
            v-model="computedValues[child.field]"
            :defaultValue="defaultValue[child.field]"
            :formElement="child"
            :disabled="disabled"
            :error="getErrorsForField(child.field)"
            @change="e => setFieldValue(child.field, e)"
            @reload="$emit('reload', $event)"
            @updateRepeatableError="updateRepeatableError"
          />
        </div>
      </template>
      <div v-else>
        <tabbed-composite
          v-model="computedValues"
          :defaultValue="defaultValue"
          :element="element"
          :disabled="disabled"
          :depth="depth"
          :error="error"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CompositeFormElement',
  props: {
    elements: {
      type: Array,
      required: true,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
    },
    error: {
      type: [Array, Object],
    },
    depth: {
      type: Number,
      default: 0,
    },
    defaultValue: {
      type: [Object, String, Number],
      default: () => ({}),
    },
  },
  emits: ['change', 'reload', 'updateRepeatableError'],
  computed: {
    computedValues: {
      get() {
        return this.values;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    groupedElements() {
      return Object.values(this.elements.reduce((acc, e) => {
        if (!this.isComposite(e) || this.isIndented(e)) {
          return [...acc, e];
        }
        const composite = { type: 'composite', collapsible: e.collapsible };
        if (!acc.composite) {
          acc.composite = { ...composite, children: [e] };
        } else {
          const children = acc.composite.children;
          acc.composite = { ...composite, children: [...children, e] };
        }
        return acc;
      }, []));
    },
  },
  methods: {
    setFieldValue(field, val) {
      const copyComputed = { ...this.computedValues, [field]: val };
      this.computedValues = copyComputed;
    },
    isComposite(e) {
      return e.type === 'composite';
    },
    isIndented(element) {
      return !element.collapsible;
    },
    getErrorsForField(feField) {
      if (!this.error) {
        return undefined;
      }
      const associatedErrors = this.error.filter(e => e.context.field[0] === feField);
      return associatedErrors.length === 0 ? undefined : associatedErrors;
    },
    updateRepeatableError(setToRemove, setToAdd) {
      this.$emit('updateRepeatableError', setToRemove, setToAdd);
    },
  },
};
</script>
<style scoped lang="scss">
.indented {
  margin-left: 60px;
}
.label {
  margin-bottom: 25px;
}
</style>
