<template>
  <cmc-read-only
    :id="id ? `cmc-text-input-${id}` : undefined"
    :label="label"
    :model-value="modelValue"
    :with-label-i18n="withLabelI18n"
    :read-only="readOnly"
    :inherit-read-only="inheritReadOnly"
    :with-sensitive="type === 'password'"
    :with-error-text="withErrorText"
    :with-error-text-i18n="withErrorTextI18n"
  >
    <cmc-stack
      :class="[
        'cmc-text-input',
        { 'cmc-text-input-as-status-icon': showStatusIcon },
      ]"
      spacing="3xs"
    >
      <cmc-label
        v-bind="props"
        as-header
      ></cmc-label>
      <div
        :class="['cmc-text-input-wrapper', {'cmc-text-input-wrapper-as-search': asSearch}]"
      >
        <slot name="lhs" />
        <cmc-icon
          v-if="asSearch"
          icon="search"
          svg
          class="search-icon"
        ></cmc-icon>
        <input
          ref="inputEl"
          :type="type"
          :value="modelValue"
          :placeholder="adaptedPlaceholder"
          :disabled="disabled"
          @input="emit('update:modelValue', ($event.target as HTMLInputElement)?.value, inputEl as HTMLInputElement)"
          @click="emit('click', $event)"
          @focus="emit('focus', $event)"
          @blur="emit('blur', $event)"
          @keypress="type === 'number' ? filterOnlyNumeric($event) : () => null"
        />
        <slot name="rhs" />
        <cmc-icon
          v-if="showStatusIcon"
          class="status-icon"
          :icon="withValid ? 'check-circle' : 'times-circle'"
          :color="withValid ? 'green' : 'red'"
          size="l"
        ></cmc-icon>
      </div>
      <cmc-text
        v-if="withErrorText"
        :text="withErrorText"
        :with-i18n="withErrorTextI18n"
        as-error
        size="m"
      ></cmc-text>
    </cmc-stack>
  </cmc-read-only>
</template>

<script setup lang="ts">
import { computed, defineComponent, ref } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import { useI18n } from 'vue-i18n';

defineComponent({
  CmcStack,
  CmcText,
  CmcLabel,
  CmcIcon,
  CmcReadOnly,
})

type TextInputType = 'text' | 'password' | 'number';

export type CmcTextInputProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Type of the text input.
   */
  type?: TextInputType;

  /**
   * Value of the input
   */
  modelValue?: string | Number;

  /**
   * Default value on init
   */
  defaultValue?: string;

  /**
   * Label on top of the input
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Set a placeholder in the input.
   */
  placeholder?: string;

  /**
   * True if the placeholder is an i18n label.
   */
  withPlaceholderI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Make the input read-only.
   */
  readOnly?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean

  /**
   * Will show a valid icon on the right-hand side.
   */
  withValid?: boolean;

  /**
   * Will show an error text under the input.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Disable the input
   */
  disabled?: boolean;

  /**
   * Should render the input as a search input.
   */
  asSearch?: boolean;

  /**
   * True if the input should allow decimal values when the input type is 'number'
   */
  decimal?: boolean;

  /**
   * Whether to show or hide the error status icon within the input when an error is displayed
   */
  hideErrorStatusIcon?: boolean;

  /**
   * True if the input is optional.
   */
  asOptional?: boolean;

  /**
   * Whether to show or hide the error status icon within the input when an error is displayed
   */
  showErrorStatusIcon?: boolean;
}
const props = withDefaults(defineProps<CmcTextInputProps>(), {
  type: 'text',
  inheritReadOnly: true,
  decimal: true,
  hideErrorStatusIcon: false,
  asOptional: false,
});

const showStatusIcon = computed(() => {
  return (props.withValid || (props.withErrorText && !props.hideErrorStatusIcon) || props.showErrorStatusIcon);
})

const emit = defineEmits<{
  /**
   * Emitted when input was changed.
   * @arg text value
   */
  (event: 'update:modelValue', str: string, scrollRef: HTMLInputElement): void

  /**
   * On click of the input
   */
  (event: 'click', e: Event): void

  /**
   * On focus of the input
   */
   (event: 'focus', e: Event): void

  /**
   * On blur of the input
   */
   (event: 'blur', e: Event): void

}>()

const { t } = useI18n()
const inputEl = ref<HTMLInputElement | null>(null);
const adaptedPlaceholder = computed(() => {
  if (props.asSearch && !props.placeholder) {
    return t('search')
  }
  if (props.placeholder) {
    return props.withPlaceholderI18n ? t(props.placeholder) : props.placeholder
  }
  return undefined;
})
const filterOnlyNumeric = (evt: KeyboardEvent) => {
  const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'e', 'E', '-'];
  if (!props.decimal) {
    keysAllowed.splice(keysAllowed.indexOf('.'), 1);
  }

  const keyPressed = evt.key;
  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault();
  }
}
</script>

<style>
:root {
  --ng-text-input-border: var(--ng-secondary-border);
  --ng-text-input-text: var(--ng-text-main);
  --ng-text-input-placeholder: var(--ng-text-description);
}
</style>

<style scoped lang="scss">
.cmc-text-input {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  flex: 1;
  .cmc-text {
    margin-left: 0.125rem;
  }
  .cmc-text-input-wrapper {
    max-width: 24rem;
    position: relative;
    input {
      font-size: 1rem;
      border-radius: 0.1875rem;
      border: 1px solid var(--ng-text-input-border);
      color: var(--ng-text-input-text);
      padding: 0.5rem 0.75rem;
      height: 2rem;
      box-sizing: border-box;
      width: 100%;
      &::placeholder {
        color: var(--ng-text-input-placeholder);
        opacity: 1; /* Firefox compatability */
      }
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
    input[type=number]::-webkit-inner-spin-button {
      display: none;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .status-icon {
      position: absolute;
      right: 0.5rem;
      top: 0.4rem;
    }
    .search-icon {
      position: absolute;
      left: 0.5rem;
      top: 0.5rem;
      color: var(--ng-text-description);
    }
    &.cmc-text-input-wrapper-as-search {
      input {
        padding-left: 2rem;
        padding-top: calc(0.25rem + 0.125rem);
      }
    }
  }
  &.cmc-text-input-as-status-icon {
    input {
      padding-right: 2rem;
    }
  }
}
</style>
