<template>
  <cmc-stack class="cmc-list-select">
    <cmc-read-only
      :id="id ? `cmc-list-select-${id}` : undefined"
      :label="label"
      with-label-i18n
      :model-value="getCategoryLabel()"
      :read-only="readOnly"
      :inherit-read-only="inheritReadOnly"
      :with-copyable="false"
      :hide="(categories && categories.length <= 1)"
    >
      <cmc-stack spacing="none">
        <cmc-label
          v-bind="props"
          :heading="'h3'"
          as-header
        >
        </cmc-label>
        <cmc-block
          v-if="categories && categories.length > 1"
          padding-top="3xs"
        >
          <cmc-tabs
            :tabs="categories === undefined ? [] : categories"
            :activeTab="selectedCategory"
            @change="changeTab"
          />
        </cmc-block>
        <cmc-block padding-top="xl">
          <cmc-list
            :layout="layout"
            with-input="checkbox"
            :disabled="disabled"
            :model-value="modelValue"
            :paginable="shouldBePaginated"
            :with-number-of-records="activeCategoryOptions.length"
            @pageSelected="selectPage"
            @pageSizeSelected="selectPageSize"
            @update:model-value="selectRow"
          >
            <cmc-list-header
              asHighlight
              withPartialCheckBox
              :checkedState="checkedState"
              @checkedStatus="handleSectionClicked($event)"
            >
              <cmc-list-col
                v-for="(header, idx) in headers"
                :key="header.label"
              >
                <cmc-title
                  v-if="idx == 0"
                  v-bind="header"
                  :title="firstHeaderWithCount(header.label)"
                  :with-tooltip="header.withTooltip"
                  :with-tooltip-i18n="header.withTooltipI18n"
                  with-tooltip-placement="right"
                  heading="h5"
                  with-bold
                />
                <cmc-title
                  v-else
                  v-bind="header"
                  :title="header.label"
                  :with-tooltip="header.withTooltip"
                  :with-tooltip-i18n="header.withTooltipI18n"
                  with-tooltip-placement="right"
                  heading="h5"
                  with-bold
                />
              </cmc-list-col>
            </cmc-list-header>
            <cmc-list-row
              v-for="opt in optionsToDisplay"
              :key="opt.value"
              :value="opt.value"
              :checked="isChecked(opt.value)"
              @onclick="selectRow(opt.value)"
            >
              <cmc-list-col>
                <cmc-text
                  class="cmc-clickable-text"
                  v-bind="opt"
                  :text="opt.label"
                  :with-tooltip-placement="opt.withTooltipKeyValue? 'right' : 'auto'"
                  @click="selectRow(opt.value)"
                >
                  <template #lhs>
                    <cmc-icon
                      v-if="opt.imgUrl && layout[0].asColType === 'text-with-icon'"
                      :icon="opt.imgUrl"
                      size="l"
                      img
                    ></cmc-icon>
                  </template>
                  <template
                    v-if="opt.withTooltipKeyValue && Object.keys(opt.withTooltipKeyValue).length > 0"
                    #tooltip
                  >
                    <div>
                      <cmc-key-value
                        :key-values="opt.withTooltipKeyValue"
                        with-narrow
                      >
                      </cmc-key-value>
                    </div>
                  </template>
                </cmc-text>
              </cmc-list-col>
              <cmc-list-col
                v-for="(conf, idx) in opt.configs"
                :key="conf.label"
                :as-list-select-item-without-label="conf.options?.length > 1 && isSelectWithoutLabel(conf.label)"
              >
                <cmc-block
                  :padding-horizontal="(conf.options?.length ?? 0) > 1 ? 'l' : 's'"
                  :padding-right="(conf.options?.length ?? 0) > 1 ? 'none' : 's'"
                  :padding-left="layout[idx + 1].asColType !== 'number' ? 'none' : undefined"
                  padding-vertical="none"
                >
                  <cmc-align
                    v-if="conf.type == 'text' || conf.type == 'number'"
                    at-horizontal-center
                  >
                    <cmc-text-input
                      v-model="allConfigs[opt.value][conf.key]"
                      :inherit-read-only="false"
                      :type="conf.type"
                      :disabled="disableInput(opt.value)"
                      @update:modelValue="() => updateSelectValue(opt.value, conf.key)"
                    />
                  </cmc-align>
                  <cmc-align
                    v-else-if="conf.options.length > 1"
                    :at-horizontal-center="layout[idx + 1].asColType === 'number'"
                  >
                    <div>
                      <cmc-select
                        v-bind="conf"
                        v-model="allConfigs[opt.value][conf.key]"
                        :allow-empty="false"
                        :as-number="layout[idx + 1].asColType === 'number'"
                        :inherit-read-only="false"
                        :disabled="disableInput(opt.value)"
                        @update:modelValue="() => updateSelectValue(opt.value, conf.key)"
                      />
                    </div>
                  </cmc-align>
                  <cmc-text
                    v-else
                    v-bind="conf.options[0]"
                    :text="conf.options[0].label"
                    :with-i18n="conf.options[0].withLabelI18n"
                  ></cmc-text>
                </cmc-block>
              </cmc-list-col>
            </cmc-list-row>
          </cmc-list>
        </cmc-block>
      </cmc-stack>
      <template #readOnlyLayout>
        <cmc-block
          class="cmc-read-only"
          spacing="none"
          data-cmc-ro-type="list-select"
          :data-cmc-props="JSON.stringify({ ...props})"
        >
          <cmc-list-select-read-only
            v-bind="props"
            :config="{}"
          />
        </cmc-block>
      </template>
    </cmc-read-only>
  </cmc-stack>
</template>

<script setup lang="ts">
import { computed, reactive, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CmcList from '../display/list/CmcList.vue';
import CmcListHeader from '../display/list/CmcListHeader.vue';
import CmcListRow from '../display/list/CmcListRow.vue';
import CmcKeyValue from '../display/CmcKeyValue.vue';
import CmcListCol from '../display/list/CmcListCol.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import { PartialCheckboxState } from './constants';
import CmcTabs from '../navigation/CmcTabs.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcSelect from '../inputs/select/CmcSelect.vue';
import { SingleSelectOption } from './types';
import { ColLayout } from '../display/list/types';
import { Tab } from '../navigation/types';
import { ListSelectHeader, ListSelectOption } from './listSelectTypes';
import CmcListSelectReadOnly from './CmcListSelectReadOnly.vue';

type Props = {

  field?: string;
  id?: string;

  /**
   * Add a label on top of the select
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Model value - this is a list of selected objects
   */
  modelValue: any[];

  /**
   * Selected category
   */
  category?: string;

  /**
   * Categories available as tabs
   */
  categories?: Tab[];

  /**
   * Headers of the list.
   */
  headers: ListSelectHeader[];

  /**
   * Options of the list.
   */
  options: ListSelectOption[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];

  /**
   * Disable the list select.
   */
  disabled?: boolean;

  /**
   * Set the list select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
}

type CustomMap = { [key: string]: any } ;

const props = withDefaults(defineProps<Props>(), {
  inheritReadOnly: true
});

const emit = defineEmits<{
  /**
   * Emitted when element is selected, string when single-select mode
   */
  (event: 'update:modelValue', value: string | CustomMap[]): void,

  /**
   * Emitted when config option is selected
   */
  (event: 'update:category', category: string | undefined): void,
}>();

const { t } = useI18n();

const selectedPage = ref<number>(1);
const pageSize = ref<number>(5);
const modelValueRef = ref(props.modelValue);

const selectedCategory = computed<string | undefined>({
  get: () => {
    return props.categories?.length === 1 ? props.categories[0].key : props.category;
  },
  set: (newCategory) => {
    emit('update:category', newCategory);
  },
});

const activeCategoryOptions = computed<ListSelectOption[]>(() => {
  return props.options.filter((opt: ListSelectOption) => {
    return opt.category === selectedCategory.value;
  });
});

const shouldBePaginated = computed<boolean>(() => {
  return activeCategoryOptions.value.length > 5;
})

const optionsToDisplay = computed<ListSelectOption[]>(() => {
  return shouldBePaginated.value ? activeCategoryOptions.value.slice((selectedPage.value - 1 ) * pageSize.value, Math.min(activeCategoryOptions.value.length, selectedPage.value * pageSize.value)) :  activeCategoryOptions.value;
});

const field = computed<string>(() => props.field || "primary_header");

const numSelected = computed<number>(() => props.modelValue?.length || 0);

const checkedState = computed<PartialCheckboxState>(() => {
  const total = activeCategoryOptions.value.length;
  if (numSelected.value === 0) {
    return PartialCheckboxState.UNCHECKED;
  } else if (numSelected.value === total) {
    return PartialCheckboxState.CHECKED;
  }
  return PartialCheckboxState.PARTIAL;
});

let allConfigs = reactive<CustomMap>(buildAllConfig());
function buildAllConfig(): CustomMap {
  const configMap = props.options.reduce((acc, cur) => {
    acc[cur.value] = {};
    // instantiate all number inputs to 0
    cur.configs
      .filter(config => config.type === 'number')
      .forEach(numericConfig => {
        acc[cur.value][numericConfig.key] = 0;
      })
    return acc;
  }, {} as CustomMap);

  if (props.modelValue) {
    props.modelValue.forEach((item: any) => {
      configMap[item[field.value]] = { ...configMap[item[field.value]], ...item };
    });
  }
  return configMap;
}

const getCategoryLabel = (): string | number | undefined => {
  if (props.categories?.length === 1) {
    return '';
  }
  return props.categories?.find(c => c.key === props.category)?.label || '';
};

const isChecked = ((v: unknown) => (props.modelValue || []).some(modelValueArrayEntry => modelValueArrayEntry[field.value] === v))

const isSelectWithoutLabel = (selectLabel: string | undefined): boolean => {
  return selectLabel == null ||  selectLabel == undefined ||  selectLabel == '';
}

const selectRow = (v: string | string[]): void => {
  if (typeof v !== "string") {
    return;   // CmcList claims it emits string | string[], and can't index in with string[]
  }
  const selected = (props.modelValue || []);
  const selectedItemIndex = selected.findIndex(s => s[field.value] === v);
  
  if (selectedItemIndex !== -1) {
    selected.splice(selectedItemIndex, 1);
  } else {
    let config = getConfigForOption(v);
    const newItem: CustomMap = { ...config, [field.value]: v };
    selected.push(newItem);
  }

  emit('update:modelValue', selected);
};

const selectPage = (v: number): void => {
  selectedPage.value = v;
}

const selectPageSize = (v: number): void => {
  pageSize.value = v;
}

const changeTab = (v: string): void => {
  selectedCategory.value = v;
}

const updateSelectValue = (value: string, key: string): void => {
  const updatedConfig = allConfigs[value]?.[key];

  const selected = (props.modelValue || []).map((item: CustomMap) => {
    if (item[field.value] === value) {
      return { ...item, [key]: updatedConfig };
    }
    return item;
  });

  emit('update:modelValue', selected);
};

const handleSectionClicked = (checked: boolean) => {
  const options: ListSelectOption[] = optionsToDisplay.value;
  const selectedValues = (modelValueRef.value || []).map((m: CustomMap) => m[field.value]);
  const selectedValuesSet = new Set(selectedValues);

  // If 'checked' is true, add new selections
  if (checked) {
    const selectedOutOfPage = options
      .filter((o: ListSelectOption) => !selectedValuesSet.has(o.value))
      .map(o => ({
        [field.value]: o.value,
        ...getConfigForOption(o.value),
      }));
    modelValueRef.value = [...modelValueRef.value, ...selectedOutOfPage];
  } else {
    // If 'checked' is false, remove selected values
    const toRemoveValues = new Set(options.map(o => o.value));

    modelValueRef.value = modelValueRef.value.filter((item: CustomMap) =>
      !toRemoveValues.has(item[field.value])
    );
  }

  emit('update:modelValue', modelValueRef.value);
};

// reassign allConfig when props options change on section reload
watch(() => props.options, () => {
  // check if all currently selected values in modelValue are present in the available props.options
  var invalidValues: any[] = [];
  props.modelValue.forEach((selectedValue) => {
    const selectedOption = props.options.find(opt => opt.value === selectedValue[field.value]);
    if (!selectedOption) {
      invalidValues.push(selectedValue[field.value]);
    }
  });
  if (invalidValues.length > 0) {
    // remove the invalid options
    const selected = (props.modelValue || []);
    invalidValues.forEach(value => {
      const index = selected.findIndex(s => s[field.value] == value);
      if (index !== -1) {
        selected.splice(index, 1);
      }
    });
    emit('update:modelValue', selected);
  }

  reassignAllConfig();
})

// this is for when confirm on change happens and the model value is set to null
watch(() => props.modelValue, () => {
  if (!props.modelValue) {
    allConfigs = buildAllConfig()
  }
});

/**
 * If the props.options change it is possible that a selected value in the allConfigs is no longer a valid selectable option
 * In this case, we should reassign the allConfig value to the first option in the list
 */
function reassignAllConfig() {
  props.options.forEach(option => {
    // see if this option is one of the selected ones
    const objectFromModelValue = props.modelValue.find(modelValueEntry => modelValueEntry[field.value] === option.value);

    // in case a new option was added to props.options
    if (!allConfigs[option.value]) {
      allConfigs[option.value] = {};  
    }

    // iterate over all the configs for this option
    option.configs.forEach((config) => {
      if (config.type === "select") {
        // this config is of type 'select'
        var currentOption;
        const safeOptions = config.options ?? [];

        if (objectFromModelValue) {
          // this option is selected, get the config.option matching the one in the modelValue if possible
          currentOption = safeOptions.find(opt => (opt as SingleSelectOption).value == objectFromModelValue[config.key]);
        } else {
          // otherwise, get config.option matching the one in the allConfigs if possible
          currentOption = safeOptions.find(opt => (opt as SingleSelectOption).value == allConfigs[option.value][config.key]);
        }

        if (currentOption) {
          // sometimes there is a type mismatch between the value from the backend + frontend (1 vs '1.0')
          // which causes the select to appear empty (not recognize the selected value as one of the options)
          allConfigs[option.value][config.key] = (currentOption as SingleSelectOption).value;
        } else {
          // if the current selected value doesnt exist in the list of options anymore (due to reload)
          // then fall back to the first option in the list
          if (safeOptions.length > 0) {
            allConfigs[option.value][config.key] = (safeOptions[0] as SingleSelectOption).value;
          }
        }
      } else {
        var currentValue;
        if (objectFromModelValue) {
          // use existing value if present on the modelValue
          currentValue = objectFromModelValue[config.key];
        } else {
          // otherwise initialize to empty string or zero
          // (need to have the number value set to avoid 500 error from core parsing a empty string to a number)
          currentValue = config.type === 'text' ? '' : '0';
        }
        allConfigs[option.value][config.key] = currentValue;
      }
    })
  })

  // remove invalid options from the allConfig
  const validOptions = props.options.map(propOption => propOption.value);
  Object.keys(allConfigs).forEach(key => {
    if (!validOptions.includes(key)) {
      delete allConfigs[key];
    }
  });
}

const firstHeaderWithCount = (headerLabel: string): string => {
  const num = numSelected.value || 0;
  const header = t(headerLabel);
  const selected = t('selected');
  return `${header} (${num} ${selected})`;
};

const getConfigForOption = (v: any) => {
  let config;
  const option = props.options.find(opt => opt.value === v);
  const configsWithSelections = Object.keys(allConfigs).filter(k => Object.entries(allConfigs[k]).length > 0);
  if (option) {
    config = option.configs?.reduce((acc, cur) => {
      // Does allConfigs contain a selected value for this option?
      if (configsWithSelections.includes(option.value) && allConfigs[option.value][cur.key]) {
        acc[cur.key] = allConfigs[option.value][cur.key];
      } else if (cur.type === 'number') {
        acc[cur.key] = '0';
      } else if (cur.type === 'text' ) {
        acc[cur.key] = "";
      } else {
        acc[cur.key] = (cur.options[0] as SingleSelectOption).value
      }
      return acc;
    }, {} as CustomMap);
    config[field.value] = v;
  }
  return config;
}

const disableInput = (v: string) => {
  return (props.disabled || !(props.modelValue || []).map((m: CustomMap) => m[field.value]).includes(v));
}

</script>

<style scoped lang="scss">
:deep(.cmc-align:not(.ignore-cmc-align-hack) > div) {
  width: 100%;
}
:deep(.cmc-list-col-number .cmc-align > div) {
  width: auto;
}
:deep(.cmc-list-col-number .cmc-select) {
  min-width: 8rem;
}
:deep(.cmc-select) {
  max-width: 15rem;
}
:deep(.cmc-list-col .cmc-text.cmc-clickable-text) {
  cursor: pointer;
}
</style>
