import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import { ColLayout } from '../display/list/types';
import { Tab } from '../navigation/types';
import { ListSelectHeader, ListSelectOption } from './listSelectTypes';
import { ColSize } from '../layout/types';

type Props = {

  field?: string;
  id?: string;

  /**
   * Add a label on top of the select
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Model value of the list select. This is the value of the select row
   */
  modelValue: any;

  /**
   * Config selected for the current row.
   */
  config: { [key: string]: any };

  /**
   * Selected category
   */
  category?: string;

  /**
   * Categories available as tabs
   */
  categories?: Tab[];

  /**
   * Headers of the list.
   */
  headers: ListSelectHeader[];

  /**
   * Options of the list.
   */
  options: ListSelectOption[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];

  /**
   *  Use the same layout for all columns.  Overrides value of layout.  Will resize if number of columns is greater.
   */
  uniformLayout?: ColLayout;

  /**
   * Disable the list select.
   */
  disabled?: boolean;

  /**
   * Set the list select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
}

type CustomMap = { [key: string]: any } ;


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListSelectReadOnly',
  props: {
    field: {},
    id: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    modelValue: {},
    config: {},
    category: {},
    categories: {},
    headers: {},
    options: {},
    layout: {},
    uniformLayout: {},
    disabled: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean }
  },
  setup(__props: any) {

onMounted(() => {
  if (Array.isArray(props.modelValue)) {
    multiSelectModelValue.value = props.modelValue;
  }
});

const props = __props;

const { t } = useI18n();

const multiSelectModelValue = ref<CustomMap[]>([]);

const field = computed<string>(() => props.field || "primary_header");

/**
 * If the number of columns is larger than the uniform colsize, increase to the size of the header.
 */
const adjustedLayout = computed<ColLayout | undefined>(() => {
  if (props.uniformLayout) {
    return {
      u: getAdjustedColSize(props.uniformLayout?.u),
      sm: getAdjustedColSize(props.uniformLayout?.sm),
      md: getAdjustedColSize(props.uniformLayout?.md),
      lg: getAdjustedColSize(props.uniformLayout?.lg),
      asColType: 'text'
    };
  }
  return undefined;
});

const getAdjustedColSize = (size: ColSize | undefined) : ColSize => {
  if (!size) {
    return '1-' + props.headers.length as ColSize;
  }
  const sizeParts = size.split("-");
  const sizeLh = sizeParts[0];
  let sizeNumeric = Number.parseInt(sizeParts[1]);
  if (props.headers.length > sizeNumeric) {
    sizeNumeric = props.headers.length;
  }
  return sizeLh + "-" + sizeNumeric as ColSize;
}

const getLayout = (idx: number) : ColLayout => {
  if (adjustedLayout.value) {
    return adjustedLayout.value;
  }
  return props.layout[idx];
}

const readOnlySelectedOptions = computed(() => {
  const all = [];
  if (Array.isArray(props.modelValue)) {
    const selectedOptions = multiSelectModelValue.value;
    selectedOptions.forEach(selectedOption => {
      // Find the option passed in on props, this has the additional fields (configs) in the right order.
      // use field.value to get the name of the form field.
      const optionFromProps = props.options.find(opt => opt.value === selectedOption[field.value]);
      // The first column in the table is the value of the form field and conveniently, the first header has the label
      const adaptedConfigs = [{
        label: props.headers[0].label,
        withLabelI18n: true,
        imgUrl: optionFromProps?.imgUrl,
        value: optionFromProps?.withI18n ? t(optionFromProps.label) : optionFromProps?.label
      }];
      // Now we need to add the additional fields - the selectedOption map is not in any order - get the order from the list of fields.
      optionFromProps?.configs.forEach(configs => {
        const selectedValue = selectedOption[configs.key];
        // Sift through the config options from the props to find the label for the config option's value
        const configOptionForValue = configs.options.find(configOption => 'value' in configOption && configOption.value === selectedValue);
        adaptedConfigs.push({
          label: configs.key,
          withLabelI18n: true,
          imgUrl: undefined,
          value: configOptionForValue?.label || selectedValue.toString()
        });
      });
      all.push({ label: optionFromProps?.label, configs: adaptedConfigs });
    });
  } else {
    const option = props.options.find(opt => opt.value === props.modelValue);
    if (option) {
      const config = readOnlyConfig(option);
      all.push({ label: config[0].label, configs: config});
    }
  }
  return all;
});

const readOnlyConfig = (opt: ListSelectOption) => {
  const config = [];
  const selectedOption = props.options.find(opt => opt.value === props.modelValue);
  if (props.categories && props.categories.length >= 1) {
    config.push({
      label: props.headers[0].label,
      withLabelI18n: true,
      imgUrl: selectedOption?.imgUrl,
      value: readOnlyValue(selectedOption),
    });
  }
  if (opt && props.config) {
    config.push(...opt.configs.map((c, idx) => {
      // We initialize this value to undefined, because otherwise a blank string (e.g. '') is considered a value and will display incorrectly for configs of type select.
      let value = undefined;
      const option = c.options.find((option: any) => option.value == props.config[c.key]);
      if (option) {
        value = t(option.label, option.interpolation);
      }
      if (c.type !== 'select') {
        // if type = number / text then use the value from the input
        value = props.config[c.key];
      }
      return {
        label: props.headers[idx + 1].label,
        withLabelI18n: props.headers[idx + 1].withI18n,
        imgUrl: undefined,
        value: value,
      }
    }));
  }
  return config;
}

// Only applies to non-MultiSelect
const readOnlyValue = (selectedOption: ListSelectOption | undefined) => {
  if (selectedOption) {
    return selectedOption.withI18n ? t(selectedOption.label) : selectedOption.label;
  }
  return '';
};


return (_ctx: any,_cache: any) => {
  const _component_cmc_align = _resolveComponent("cmc-align")!

  return (_openBlock(), _createBlock(CmcStack, {
    class: "cmc-list-select-rol",
    spacing: "none"
  }, {
    default: _withCtx(() => [
      _createVNode(CmcBlock, { class: "cmc-list-select-header" }, {
        default: _withCtx(() => [
          _createVNode(CmcGrid, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, idx) => {
                return (_openBlock(), _createBlock(CmcGridCol, _mergeProps({ ref_for: true }, getLayout(idx), {
                  key: header.label
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_cmc_align, {
                      "at-horizontal-center": getLayout(idx).asColType == 'number'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcTitle, {
                          title: header.label,
                          "with-i18n": header.withI18n,
                          heading: "h5"
                        }, null, 8, ["title", "with-i18n"])
                      ]),
                      _: 2
                    }, 1032, ["at-horizontal-center"])
                  ]),
                  _: 2
                }, 1040))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(CmcBlock, { class: "cmc-list-select-rows" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(readOnlySelectedOptions.value, (row) => {
            return (_openBlock(), _createBlock(CmcBlock, {
              key: row.label,
              "padding-top": "xs"
            }, {
              default: _withCtx(() => [
                _createVNode(CmcGrid, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.configs, (roc, idx) => {
                      return (_openBlock(), _createBlock(CmcGridCol, _mergeProps({
                        key: roc.label,
                        ref_for: true
                      }, getLayout(idx)), {
                        default: _withCtx(() => [
                          _createVNode(_component_cmc_align, {
                            class: "ignore-cmc-align-hack",
                            "at-horizontal-center": _ctx.layout[idx].asColType === 'number' && !adjustedLayout.value
                          }, {
                            default: _withCtx(() => [
                              _createVNode(CmcText, {
                                text: roc.value || '',
                                size: "m",
                                "max-line-display": 1
                              }, {
                                lhs: _withCtx(() => [
                                  (roc.imgUrl && _ctx.layout[idx].asColType === 'text-with-icon')
                                    ? (_openBlock(), _createBlock(CmcIcon, {
                                        key: 0,
                                        icon: roc.imgUrl,
                                        size: "l",
                                        img: ""
                                      }, null, 8, ["icon"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["text"])
                            ]),
                            _: 2
                          }, 1032, ["at-horizontal-center"])
                        ]),
                        _: 2
                      }, 1040))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})