import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import { ColLayout } from '../display/list/types';

type FormOption = {
  interpolation: any;
  type: string;
  name: string;
  is18n?: boolean;
  disabled?: boolean;
  isLeaf?: boolean;
  children?: Object[];
  value: string;
  imageUrl?: string;
  icon?: string;
  titles?: Object[];
  options: FormOption[];
  group?: string;
  detail?: string;
  withDetailI18n?: boolean;
  color?: string;
}
type HeadersMetadata = {
  label: string;
  type: string;
  tooltip: string;
}
type Field = {
  label: string;
  options?: FormOption[];
  disabled: boolean;
  optional: boolean;
  required: boolean;
  field: string;
  reloadOnChange: boolean;
  sectionsToReload: string[];
  type: string;
}
type EntryColumn = {
  field: Field;
}
type Entry = {
  label: string;
  value: string;
  tooltip: string;
  tooltipKeyValue: {[key: string]: string};
  withTooltipI18n: boolean;
  columns: EntryColumn[];
  imgUrl: string;
}
type Category = {
  label: string;
  value: string;
  disabled: boolean;
  headersMetadata: HeadersMetadata[];
  entries: Entry[];
}
type Props = {
  label?: string;
  description?: String;
  formElement: Record<string, any>;
  modelValue: any;
  category?: string;
  disabled?: boolean;
  config: {[key: string]: any };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListSelectFormElement',
  props: {
    label: {},
    description: {},
    formElement: {},
    modelValue: {},
    category: {},
    disabled: { type: Boolean },
    config: {}
  },
  emits: ["update:modelValue", "update:config", "update:category"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const listSelectHeaders = computed(() => {
  return [{
    label: props.formElement.firstColumnHeader.label,
    type: props.formElement.firstColumnHeader.type,
    tooltip: ""
  }].concat(props.formElement.categories[0].headersMetadata || []).map((h: HeadersMetadata)  => {
    return ({
        label: h.label,
        type: h.type,
        withI18n: true,
        withTooltip: h.tooltip,
        withTooltipI18n: true,
      });
  })
})
const listSelectCategories = computed(() => {
  return (props.formElement.categories ||[]).map((c: Category) => {
    return ({
      key: c.value,
      label: c.label,
      withI18n: true
    })
  })
})
const listSelectOptions = computed(() => {
  return (props.formElement.categories ||[]).map((c: Category) => {
    return (c.entries || []).map((e: Entry) => {
      return ({
        key: e.value,
        value: e.value,
        label: e.label,
        withI18n: false,
        imgUrl: e.imgUrl,
        category: c.value,
        withTooltip: e.tooltip,
        withTooltipKeyValue: e.tooltipKeyValue,
        withTooltipI18n: true,
        configs: (e.columns || []).map((ec: EntryColumn) => {
          return ({
            key: ec.field.field,
            type: ec.field.type,
            options: (ec.field.options || []).map((o: FormOption) => {
              return ({
                withLabelI18n: o.is18n,
                value: o.value,
                label: o.name,
                detail: o.detail,
                interpolation: o.interpolation,
                withDetailI18n: true
              })
            })
          });
        })
      })
    })
  }).reduce((acc: [], g: []) => acc.concat(g), [])
})
const listSelectLayout = computed<ColLayout[]>(() => {
  return (listSelectHeaders.value || []).map(header => {
    return ({
      u: `1-${listSelectHeaders.value.length}`,
      asColType: header.type
    } as ColLayout)
  })
})

const field = computed<string>(() => {
  if (asMultiSelect.value) {
    return props.formElement.firstColumnHeader.field;
  }
  return props.formElement.field;
});

const asMultiSelect = computed<boolean>(() => props.formElement.type === 'listMultiSelect' || false);

const emit = __emit

function inputChange(v: Object | Date | String | Number | Boolean | Array<Object>) {
  emit('update:modelValue', v);
}
function configChange(config: any) {
  emit('update:config', config);
}
function categoryChange(category: any) {
  emit('update:category', category);
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_list_select = _resolveComponent("cmc-list-select")!
  const _component_cmc_list_select_as_multi = _resolveComponent("cmc-list-select-as-multi")!

  return (!asMultiSelect.value)
    ? (_openBlock(), _createBlock(_component_cmc_list_select, {
        key: 0,
        field: field.value,
        label: _ctx.label,
        "with-label-i18n": "",
        "with-tooltip": _ctx.description,
        "with-tooltip-i18n": "",
        headers: listSelectHeaders.value,
        categories: listSelectCategories.value,
        options: listSelectOptions.value,
        layout: listSelectLayout.value,
        modelValue: _ctx.modelValue,
        category: _ctx.category,
        config: _ctx.config,
        disabled: _ctx.disabled,
        "onUpdate:modelValue": inputChange,
        "onUpdate:category": categoryChange,
        "onUpdate:config": configChange
      }, null, 8, ["field", "label", "with-tooltip", "headers", "categories", "options", "layout", "modelValue", "category", "config", "disabled"]))
    : (_openBlock(), _createBlock(_component_cmc_list_select_as_multi, {
        key: 1,
        field: field.value,
        label: _ctx.label,
        "with-label-i18n": "",
        "with-tooltip": _ctx.description,
        "with-tooltip-i18n": "",
        headers: listSelectHeaders.value,
        categories: listSelectCategories.value,
        options: listSelectOptions.value,
        layout: listSelectLayout.value,
        modelValue: _ctx.modelValue || [],
        category: _ctx.category,
        disabled: _ctx.disabled,
        "onUpdate:modelValue": inputChange,
        "onUpdate:category": categoryChange
      }, null, 8, ["field", "label", "with-tooltip", "headers", "categories", "options", "layout", "modelValue", "category", "disabled"]))
}
}

})