import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { ref, watch, provide, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import CmcRow, { ElementSpecificStyles } from '@/components/nextgen/display/CmcRow.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';
import CmcBlock from '@/components/nextgen/layout/CmcBlock.vue';
import CmcGroup from '@/components/nextgen/layout/CmcGroup.vue';
import CmcAlert from './CmcAlert.vue';
import { CMC_CONTAINED_IN } from '@/components/nextgen/constants';
import { RowError, NestedErrors } from './types';
import { FormElement } from '@/app/Main/Services/components/models';

interface ItemWithId extends Record<string, any> {
  id?: string;
}

interface Props {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * The array of entity objects
   */
  modelValue: Record<string, any>[];

  /**
   *  Max number of rows to allow before disabling the 'add' button and showing a warning
   */
  maxNumRows?: number;

  /**
   * The row formElement that will be used as a template for each row
   */
  formElement: FormElement;

  /**
   * The errors object that contains the errors for each row
   */
  errors?: RowError[];

  /**
   * Whether to show the plus button on all rows rather than just the last one
   */
  showPlusOnAllRows?: boolean;
}

const CMC_ROW_REPEATABLE = 'cmc-row-repeatable';

/**
 * Form elements that always show labels in repeated rows.
 * Header row (index 0) always shows all labels, but in subsequent rows,
 * only these elements display labels to maintain context while reducing clutter.
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcRowRepeatable',
  props: {
    id: {},
    modelValue: {},
    maxNumRows: {},
    formElement: {},
    errors: { default: () => ([]) },
    showPlusOnAllRows: { type: Boolean, default: false }
  },
  emits: ["change", "reload", "update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

/**
 * The name of the component that contains this component.
 */
const FORM_ELEMENTS_WITH_LABEL = ['checkbox'];

const maxRowsReached = computed<boolean>(() => {
  return !!props.maxNumRows && items.value.length >= props.maxNumRows;
});

/**
 * Ensures each item has a unique ID
 * @param items - Array of items to process
 * @returns Array of items with IDs
 */
const ensureItemsHaveIds = (items: Record<string, any>[]): ItemWithId[] => {
  return items.map(item => {
    if (!item.id) {
      return { ...item, id: uuidv4() };
    }
    return item;
  });
};

// Initialize items with IDs
const items = ref<ItemWithId[]>(ensureItemsHaveIds([...props.modelValue]));

// Initialize errors object
const errorsMap = ref<NestedErrors>({});

// If items array is empty, initialize with a single empty row
if (items.value.length === 0) {
  items.value = [{ id: uuidv4() }];
}

watch(() => props.modelValue, (newValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(items.value)) {
    items.value = ensureItemsHaveIds([...newValue]);

    if (items.value.length === 0) {
      items.value = [{ id: uuidv4() }];
    }
  }
}, { deep: true });

/**
 * Element-specific styles for header row elements
 */
const HEADER_ROW_STYLES: ElementSpecificStyles = {
  'CHECKBOX': {
    paddingTop: 'xl',
  }
};

/**
 * Returns element-specific styles for header row elements
 * Only applies styles to the first row (index 0)
 */
const getElementSpecificStylesForHeaderRow = (index: number): ElementSpecificStyles => {
  if (index !== 0) {
    return {};
  }

  const result: ElementSpecificStyles = {};
  const formElements = props.formElement?.formElements || [];

  for (const element of formElements) {
    const type = element.type.toUpperCase();
    if (HEADER_ROW_STYLES[type]) {
      result[type] = HEADER_ROW_STYLES[type];
    }
  }

  return result;
};

/**
 * Check if any field in the row has a value
 */
const hasAnyFieldValue = (index: number): boolean => {
  const item = items.value[index];
  return Object.entries(item)
    .filter(([key]) => key !== 'id') // Exclude the id field from check
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .some(([_, value]) => {
      if (value === null || value === undefined) return false;
      if (typeof value === 'string') return value.trim() !== '';
      return true;
    });
};

/**
 * Check if all required fields in the row have values
 */
const hasAllRequiredFields = (index: number): boolean => {
  const item = items.value[index];

  const requiredFields = props.formElement.formElements
    .filter(fe => fe.required)
    .map(fe => fe.field);

  // Check if all required fields have values
  return requiredFields.every(field => {
    const value = item[field];
    if (value === null || value === undefined) return false;
    if (typeof value === 'string') return value.trim() !== '';
    return true;
  });
};

/**
 * Determine if the plus button should be shown for a row
 */
const showPlusButton = (index: number): boolean => {
  return index === items.value.length - 1;
};

/**
 * Determine if the plus button should be disabled
 */
const isPlusButtonDisabled = (index: number): boolean => {
  return !hasAllRequiredFields(index) || maxRowsReached.value;
};

/**
 * Determine if the minus button should be disabled
 */
const isMinusButtonDisabled = (index: number): boolean => {
  if (items.value.length > 1) {
    return false;
  }

  // For single row: minus button is disabled if all fields are empty
  return !hasAnyFieldValue(index);
};

/**
 * Handle click on the plus button to add a new row
 */
const handlePlusClick = (index: number) => {
  if (props.showPlusOnAllRows && index < items.value.length - 1) {
    items.value.splice(index + 1, 0, { id: uuidv4() });
  } else {
    items.value.push({ id: uuidv4() });
  }

  emit('update:modelValue', items.value);
};

/**
 * Handle click on the minus button
 */
const handleMinusClick = (index: number) => {
  if (items.value.length === 1) {
    items.value[0] = { id: uuidv4() };

    // Reset toggle components to initial state
    props.formElement.formElements.forEach(fe => {
      if (fe.type === 'checkbox') {
        items.value[0][fe.field] = false;
      }
    });
  } else {
    items.value.splice(index, 1);
  }

  // Remove errors for the removed row
  removeErrorsForRow(index);

  emit('update:modelValue', items.value);
};

/**
 * Remove errors for a row  
 * @param index - The index of the row
 */
const removeErrorsForRow = (index: number) => {
  // Shift all error entries down by one, starting from the removed index
  const newErrorsMap = Object.entries(errorsMap.value)
    .filter(([key]) => Number(key) !== index) // Remove the target index
    .reduce((acc, [key, value]) => {
      const numKey = Number(key);
      // Shift keys down by 1 if they're after the removed index
      const newKey = numKey > index ? numKey - 1 : numKey;
      acc[newKey] = value;
      return acc;
    }, {} as typeof errorsMap.value);

  errorsMap.value = newErrorsMap;
};

/**
 * Handle changes in a row
 */
const handleRowChange = (index: number, formElement: FormElement) => {
  emit('change', formElement);
};

/**
 * Handle entity updates from a row
 * @param index - The index of the row
 * @param field - The field to update
 * @param value - The value to update the field to
 */
const handleUpdateEntity = (index: number, field: string, value: object): void => {
  // Entity's value will ideally be a string, number, boolean
  // This does gets invoked with null/undefined values we need to ignore 
  // them to avoid resetting the row values
  if (value == null) return;
  
  const updatedItems = items.value.map((item, i) => 
    i === index ? { ...item, [field]: value } : item
  );
  items.value = updatedItems;
  emit('update:modelValue', updatedItems);
};

/**
 * Process formElement to hide labels after the first row
 */
const getProcessedFormElement = (index: number) => {
  // Don't hide label for first row
  if (index === 0 ) {
    return props.formElement;
  }

  const processedFormElement = JSON.parse(JSON.stringify(props.formElement)) as FormElement;

  // Reset label property to empty
  processedFormElement.formElements = processedFormElement.formElements.map(element => {
    const newElement = { ...element };
    const hideLabel = !FORM_ELEMENTS_WITH_LABEL.includes(element.type);
    if (hideLabel) {
      newElement.label = '';
    }
    return newElement;
  });

  return processedFormElement;
};

/**
 * Processes a single row error and adds it to the accumulated errors object
 * @param error - The error object containing message, errorCode and context
 * @param accumulator - The accumulated errors object
 * @returns Updated accumulated errors object
 */
function handleRowRepeatableError(error: RowError, accumulator: NestedErrors): NestedErrors {
  const { field } = error.context;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, index, child] = field;

  // Initialize nested objects if they don't exist
  accumulator[index] = accumulator[index] || {};
  accumulator[index][child] = accumulator[index][child] || [];

  accumulator[index][child].push(error);
  return accumulator;
}

// Watch for changes in errors prop
watch(
  () => props.errors,
  (newValues: RowError[]) => {
    errorsMap.value = Array.isArray(newValues)
      ? newValues.reduce<NestedErrors>((acc, error) => handleRowRepeatableError(error, acc), {})
      : {};
  },
  { immediate: true }
);

// Provide the context that this is a row repeatable component
provide(CMC_CONTAINED_IN, CMC_ROW_REPEATABLE);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, { class: "cmc-row-repeatable" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
        return (_openBlock(), _createBlock(CmcGroup, {
          key: item.id || index,
          class: "cmc-row-repeatable-item",
          spacing: "3xs"
        }, {
          default: _withCtx(() => [
            _createVNode(CmcRow, {
              class: _normalizeClass(["repeatable-row", { 'first-row': index === 0 }]),
              "padding-bottom": "3xs",
              entity: item,
              formElement: getProcessedFormElement(index),
              "element-styles": getElementSpecificStylesForHeaderRow(index),
              errors: errorsMap.value[index] || {},
              onChange: ($event: any) => (handleRowChange(index, $event)),
              onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload', $event))),
              "onUpdate:entity": (field, value) => handleUpdateEntity(index, field, value)
            }, null, 8, ["class", "entity", "formElement", "element-styles", "errors", "onChange", "onUpdate:entity"]),
            _createVNode(CmcBlock, { class: "cmc-row-repeatable-buttons" }, {
              default: _withCtx(() => [
                _createVNode(CmcButton, {
                  "lhs-icon": 'minus',
                  "lhs-icon-svg": true,
                  disabled: isMinusButtonDisabled(index),
                  "icon-only": "",
                  onClick: ($event: any) => (handleMinusClick(index))
                }, null, 8, ["disabled", "onClick"]),
                (props.showPlusOnAllRows || showPlusButton(index))
                  ? (_openBlock(), _createBlock(CmcButton, {
                      key: 0,
                      "lhs-icon": 'plus',
                      "lhs-icon-svg": true,
                      disabled: isPlusButtonDisabled(index),
                      "icon-only": "",
                      onClick: ($event: any) => (handlePlusClick(index))
                    }, null, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createVNode(CmcBlock, { "padding-top": "3xs" }, {
        default: _withCtx(() => [
          (maxRowsReached.value)
            ? (_openBlock(), _createBlock(CmcAlert, {
                key: 0,
                text: "row_repeatable_limit",
                "with-i18n": ""
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})