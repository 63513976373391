import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots, unref as _unref, resolveComponent as _resolveComponent } from "vue"

import { computed, reactive, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CmcList from '../display/list/CmcList.vue';
import CmcListHeader from '../display/list/CmcListHeader.vue';
import CmcListRow from '../display/list/CmcListRow.vue';
import CmcKeyValue from '../display/CmcKeyValue.vue';
import CmcListCol from '../display/list/CmcListCol.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import { PartialCheckboxState } from './constants';
import CmcTabs from '../navigation/CmcTabs.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcSelect from '../inputs/select/CmcSelect.vue';
import { SingleSelectOption } from './types';
import { ColLayout } from '../display/list/types';
import { Tab } from '../navigation/types';
import { ListSelectHeader, ListSelectOption } from './listSelectTypes';
import CmcListSelectReadOnly from './CmcListSelectReadOnly.vue';

type Props = {

  field?: string;
  id?: string;

  /**
   * Add a label on top of the select
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Model value - this is a list of selected objects
   */
  modelValue: any[];

  /**
   * Selected category
   */
  category?: string;

  /**
   * Categories available as tabs
   */
  categories?: Tab[];

  /**
   * Headers of the list.
   */
  headers: ListSelectHeader[];

  /**
   * Options of the list.
   */
  options: ListSelectOption[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];

  /**
   * Disable the list select.
   */
  disabled?: boolean;

  /**
   * Set the list select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
}

type CustomMap = { [key: string]: any } ;


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListSelectAsMulti',
  props: {
    field: {},
    id: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    modelValue: {},
    category: {},
    categories: {},
    headers: {},
    options: {},
    layout: {},
    disabled: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean }
  },
  emits: ["update:modelValue", "update:category"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { t } = useI18n();

const selectedPage = ref<number>(1);
const pageSize = ref<number>(5);
const modelValueRef = ref(props.modelValue);

const selectedCategory = computed<string | undefined>({
  get: () => {
    return props.categories?.length === 1 ? props.categories[0].key : props.category;
  },
  set: (newCategory) => {
    emit('update:category', newCategory);
  },
});

const activeCategoryOptions = computed<ListSelectOption[]>(() => {
  return props.options.filter((opt: ListSelectOption) => {
    return opt.category === selectedCategory.value;
  });
});

const shouldBePaginated = computed<boolean>(() => {
  return activeCategoryOptions.value.length > 5;
})

const optionsToDisplay = computed<ListSelectOption[]>(() => {
  return shouldBePaginated.value ? activeCategoryOptions.value.slice((selectedPage.value - 1 ) * pageSize.value, Math.min(activeCategoryOptions.value.length, selectedPage.value * pageSize.value)) :  activeCategoryOptions.value;
});

const field = computed<string>(() => props.field || "primary_header");

const numSelected = computed<number>(() => props.modelValue?.length || 0);

const checkedState = computed<PartialCheckboxState>(() => {
  const total = activeCategoryOptions.value.length;
  if (numSelected.value === 0) {
    return PartialCheckboxState.UNCHECKED;
  } else if (numSelected.value === total) {
    return PartialCheckboxState.CHECKED;
  }
  return PartialCheckboxState.PARTIAL;
});

let allConfigs = reactive<CustomMap>(buildAllConfig());
function buildAllConfig(): CustomMap {
  const configMap = props.options.reduce((acc, cur) => {
    acc[cur.value] = {};
    // instantiate all number inputs to 0
    cur.configs
      .filter(config => config.type === 'number')
      .forEach(numericConfig => {
        acc[cur.value][numericConfig.key] = 0;
      })
    return acc;
  }, {} as CustomMap);

  if (props.modelValue) {
    props.modelValue.forEach((item: any) => {
      configMap[item[field.value]] = { ...configMap[item[field.value]], ...item };
    });
  }
  return configMap;
}

const getCategoryLabel = (): string | number | undefined => {
  if (props.categories?.length === 1) {
    return '';
  }
  return props.categories?.find(c => c.key === props.category)?.label || '';
};

const isChecked = ((v: unknown) => (props.modelValue || []).some(modelValueArrayEntry => modelValueArrayEntry[field.value] === v))

const isSelectWithoutLabel = (selectLabel: string | undefined): boolean => {
  return selectLabel == null ||  selectLabel == undefined ||  selectLabel == '';
}

const selectRow = (v: string | string[]): void => {
  if (typeof v !== "string") {
    return;   // CmcList claims it emits string | string[], and can't index in with string[]
  }
  const selected = (props.modelValue || []);
  const selectedItemIndex = selected.findIndex(s => s[field.value] === v);
  
  if (selectedItemIndex !== -1) {
    selected.splice(selectedItemIndex, 1);
  } else {
    let config = getConfigForOption(v);
    const newItem: CustomMap = { ...config, [field.value]: v };
    selected.push(newItem);
  }

  emit('update:modelValue', selected);
};

const selectPage = (v: number): void => {
  selectedPage.value = v;
}

const selectPageSize = (v: number): void => {
  pageSize.value = v;
}

const changeTab = (v: string): void => {
  selectedCategory.value = v;
}

const updateSelectValue = (value: string, key: string): void => {
  const updatedConfig = allConfigs[value]?.[key];

  const selected = (props.modelValue || []).map((item: CustomMap) => {
    if (item[field.value] === value) {
      return { ...item, [key]: updatedConfig };
    }
    return item;
  });

  emit('update:modelValue', selected);
};

const handleSectionClicked = (checked: boolean) => {
  const options: ListSelectOption[] = optionsToDisplay.value;
  const selectedValues = (modelValueRef.value || []).map((m: CustomMap) => m[field.value]);
  const selectedValuesSet = new Set(selectedValues);

  // If 'checked' is true, add new selections
  if (checked) {
    const selectedOutOfPage = options
      .filter((o: ListSelectOption) => !selectedValuesSet.has(o.value))
      .map(o => ({
        [field.value]: o.value,
        ...getConfigForOption(o.value),
      }));
    modelValueRef.value = [...modelValueRef.value, ...selectedOutOfPage];
  } else {
    // If 'checked' is false, remove selected values
    const toRemoveValues = new Set(options.map(o => o.value));

    modelValueRef.value = modelValueRef.value.filter((item: CustomMap) =>
      !toRemoveValues.has(item[field.value])
    );
  }

  emit('update:modelValue', modelValueRef.value);
};

// reassign allConfig when props options change on section reload
watch(() => props.options, () => {
  // check if all currently selected values in modelValue are present in the available props.options
  var invalidValues: any[] = [];
  props.modelValue.forEach((selectedValue) => {
    const selectedOption = props.options.find(opt => opt.value === selectedValue[field.value]);
    if (!selectedOption) {
      invalidValues.push(selectedValue[field.value]);
    }
  });
  if (invalidValues.length > 0) {
    // remove the invalid options
    const selected = (props.modelValue || []);
    invalidValues.forEach(value => {
      const index = selected.findIndex(s => s[field.value] == value);
      if (index !== -1) {
        selected.splice(index, 1);
      }
    });
    emit('update:modelValue', selected);
  }

  reassignAllConfig();
})

// this is for when confirm on change happens and the model value is set to null
watch(() => props.modelValue, () => {
  if (!props.modelValue) {
    allConfigs = buildAllConfig()
  }
});

/**
 * If the props.options change it is possible that a selected value in the allConfigs is no longer a valid selectable option
 * In this case, we should reassign the allConfig value to the first option in the list
 */
function reassignAllConfig() {
  props.options.forEach(option => {
    // see if this option is one of the selected ones
    const objectFromModelValue = props.modelValue.find(modelValueEntry => modelValueEntry[field.value] === option.value);

    // in case a new option was added to props.options
    if (!allConfigs[option.value]) {
      allConfigs[option.value] = {};  
    }

    // iterate over all the configs for this option
    option.configs.forEach((config) => {
      if (config.type === "select") {
        // this config is of type 'select'
        var currentOption;
        const safeOptions = config.options ?? [];

        if (objectFromModelValue) {
          // this option is selected, get the config.option matching the one in the modelValue if possible
          currentOption = safeOptions.find(opt => (opt as SingleSelectOption).value == objectFromModelValue[config.key]);
        } else {
          // otherwise, get config.option matching the one in the allConfigs if possible
          currentOption = safeOptions.find(opt => (opt as SingleSelectOption).value == allConfigs[option.value][config.key]);
        }

        if (currentOption) {
          // sometimes there is a type mismatch between the value from the backend + frontend (1 vs '1.0')
          // which causes the select to appear empty (not recognize the selected value as one of the options)
          allConfigs[option.value][config.key] = (currentOption as SingleSelectOption).value;
        } else {
          // if the current selected value doesnt exist in the list of options anymore (due to reload)
          // then fall back to the first option in the list
          if (safeOptions.length > 0) {
            allConfigs[option.value][config.key] = (safeOptions[0] as SingleSelectOption).value;
          }
        }
      } else {
        var currentValue;
        if (objectFromModelValue) {
          // use existing value if present on the modelValue
          currentValue = objectFromModelValue[config.key];
        } else {
          // otherwise initialize to empty string or zero
          // (need to have the number value set to avoid 500 error from core parsing a empty string to a number)
          currentValue = config.type === 'text' ? '' : '0';
        }
        allConfigs[option.value][config.key] = currentValue;
      }
    })
  })

  // remove invalid options from the allConfig
  const validOptions = props.options.map(propOption => propOption.value);
  Object.keys(allConfigs).forEach(key => {
    if (!validOptions.includes(key)) {
      delete allConfigs[key];
    }
  });
}

const firstHeaderWithCount = (headerLabel: string): string => {
  const num = numSelected.value || 0;
  const header = t(headerLabel);
  const selected = t('selected');
  return `${header} (${num} ${selected})`;
};

const getConfigForOption = (v: any) => {
  let config;
  const option = props.options.find(opt => opt.value === v);
  const configsWithSelections = Object.keys(allConfigs).filter(k => Object.entries(allConfigs[k]).length > 0);
  if (option) {
    config = option.configs?.reduce((acc, cur) => {
      // Does allConfigs contain a selected value for this option?
      if (configsWithSelections.includes(option.value) && allConfigs[option.value][cur.key]) {
        acc[cur.key] = allConfigs[option.value][cur.key];
      } else if (cur.type === 'number') {
        acc[cur.key] = '0';
      } else if (cur.type === 'text' ) {
        acc[cur.key] = "";
      } else {
        acc[cur.key] = (cur.options[0] as SingleSelectOption).value
      }
      return acc;
    }, {} as CustomMap);
    config[field.value] = v;
  }
  return config;
}

const disableInput = (v: string) => {
  return (props.disabled || !(props.modelValue || []).map((m: CustomMap) => m[field.value]).includes(v));
}


return (_ctx: any,_cache: any) => {
  const _component_cmc_text_input = _resolveComponent("cmc-text-input")!
  const _component_cmc_align = _resolveComponent("cmc-align")!

  return (_openBlock(), _createBlock(CmcStack, { class: "cmc-list-select" }, {
    default: _withCtx(() => [
      _createVNode(CmcReadOnly, {
        id: _ctx.id ? `cmc-list-select-${_ctx.id}` : undefined,
        label: _ctx.label,
        "with-label-i18n": "",
        "model-value": getCategoryLabel(),
        "read-only": _ctx.readOnly,
        "inherit-read-only": _ctx.inheritReadOnly,
        "with-copyable": false,
        hide: (_ctx.categories && _ctx.categories.length <= 1)
      }, {
        readOnlyLayout: _withCtx(() => [
          _createVNode(CmcBlock, {
            class: "cmc-read-only",
            spacing: "none",
            "data-cmc-ro-type": "list-select",
            "data-cmc-props": JSON.stringify({ ...props})
          }, {
            default: _withCtx(() => [
              _createVNode(CmcListSelectReadOnly, _mergeProps(props, { config: {} }), null, 16)
            ]),
            _: 1
          }, 8, ["data-cmc-props"])
        ]),
        default: _withCtx(() => [
          _createVNode(CmcStack, { spacing: "none" }, {
            default: _withCtx(() => [
              _createVNode(CmcLabel, _mergeProps(props, {
                heading: 'h3',
                "as-header": ""
              }), null, 16),
              (_ctx.categories && _ctx.categories.length > 1)
                ? (_openBlock(), _createBlock(CmcBlock, {
                    key: 0,
                    "padding-top": "3xs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcTabs, {
                        tabs: _ctx.categories === undefined ? [] : _ctx.categories,
                        activeTab: selectedCategory.value,
                        onChange: changeTab
                      }, null, 8, ["tabs", "activeTab"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(CmcBlock, { "padding-top": "xl" }, {
                default: _withCtx(() => [
                  _createVNode(CmcList, {
                    layout: _ctx.layout,
                    "with-input": "checkbox",
                    disabled: _ctx.disabled,
                    "model-value": _ctx.modelValue,
                    paginable: shouldBePaginated.value,
                    "with-number-of-records": activeCategoryOptions.value.length,
                    onPageSelected: selectPage,
                    onPageSizeSelected: selectPageSize,
                    "onUpdate:modelValue": selectRow
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcListHeader, {
                        asHighlight: "",
                        withPartialCheckBox: "",
                        checkedState: checkedState.value,
                        onCheckedStatus: _cache[0] || (_cache[0] = ($event: any) => (handleSectionClicked($event)))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, idx) => {
                            return (_openBlock(), _createBlock(CmcListCol, {
                              key: header.label
                            }, {
                              default: _withCtx(() => [
                                (idx == 0)
                                  ? (_openBlock(), _createBlock(CmcTitle, _mergeProps({
                                      key: 0,
                                      ref_for: true
                                    }, header, {
                                      title: firstHeaderWithCount(header.label),
                                      "with-tooltip": header.withTooltip,
                                      "with-tooltip-i18n": header.withTooltipI18n,
                                      "with-tooltip-placement": "right",
                                      heading: "h5",
                                      "with-bold": ""
                                    }), null, 16, ["title", "with-tooltip", "with-tooltip-i18n"]))
                                  : (_openBlock(), _createBlock(CmcTitle, _mergeProps({
                                      key: 1,
                                      ref_for: true
                                    }, header, {
                                      title: header.label,
                                      "with-tooltip": header.withTooltip,
                                      "with-tooltip-i18n": header.withTooltipI18n,
                                      "with-tooltip-placement": "right",
                                      heading: "h5",
                                      "with-bold": ""
                                    }), null, 16, ["title", "with-tooltip", "with-tooltip-i18n"]))
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["checkedState"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsToDisplay.value, (opt) => {
                        return (_openBlock(), _createBlock(CmcListRow, {
                          key: opt.value,
                          value: opt.value,
                          checked: isChecked(opt.value),
                          onOnclick: ($event: any) => (selectRow(opt.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(CmcListCol, null, {
                              default: _withCtx(() => [
                                _createVNode(CmcText, _mergeProps({
                                  class: "cmc-clickable-text",
                                  ref_for: true
                                }, opt, {
                                  text: opt.label,
                                  "with-tooltip-placement": opt.withTooltipKeyValue? 'right' : 'auto',
                                  onClick: ($event: any) => (selectRow(opt.value))
                                }), _createSlots({
                                  lhs: _withCtx(() => [
                                    (opt.imgUrl && _ctx.layout[0].asColType === 'text-with-icon')
                                      ? (_openBlock(), _createBlock(CmcIcon, {
                                          key: 0,
                                          icon: opt.imgUrl,
                                          size: "l",
                                          img: ""
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, [
                                  (opt.withTooltipKeyValue && Object.keys(opt.withTooltipKeyValue).length > 0)
                                    ? {
                                        name: "tooltip",
                                        fn: _withCtx(() => [
                                          _createElementVNode("div", null, [
                                            _createVNode(CmcKeyValue, {
                                              "key-values": opt.withTooltipKeyValue,
                                              "with-narrow": ""
                                            }, null, 8, ["key-values"])
                                          ])
                                        ]),
                                        key: "0"
                                      }
                                    : undefined
                                ]), 1040, ["text", "with-tooltip-placement", "onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opt.configs, (conf, idx) => {
                              return (_openBlock(), _createBlock(CmcListCol, {
                                key: conf.label,
                                "as-list-select-item-without-label": conf.options?.length > 1 && isSelectWithoutLabel(conf.label)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(CmcBlock, {
                                    "padding-horizontal": (conf.options?.length ?? 0) > 1 ? 'l' : 's',
                                    "padding-right": (conf.options?.length ?? 0) > 1 ? 'none' : 's',
                                    "padding-left": _ctx.layout[idx + 1].asColType !== 'number' ? 'none' : undefined,
                                    "padding-vertical": "none"
                                  }, {
                                    default: _withCtx(() => [
                                      (conf.type == 'text' || conf.type == 'number')
                                        ? (_openBlock(), _createBlock(_component_cmc_align, {
                                            key: 0,
                                            "at-horizontal-center": ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_cmc_text_input, {
                                                modelValue: _unref(allConfigs)[opt.value][conf.key],
                                                "onUpdate:modelValue": [($event: any) => ((_unref(allConfigs)[opt.value][conf.key]) = $event), () => updateSelectValue(opt.value, conf.key)],
                                                "inherit-read-only": false,
                                                type: conf.type,
                                                disabled: disableInput(opt.value)
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "type", "disabled"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : (conf.options.length > 1)
                                          ? (_openBlock(), _createBlock(_component_cmc_align, {
                                              key: 1,
                                              "at-horizontal-center": _ctx.layout[idx + 1].asColType === 'number'
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", null, [
                                                  _createVNode(CmcSelect, _mergeProps({ ref_for: true }, conf, {
                                                    modelValue: _unref(allConfigs)[opt.value][conf.key],
                                                    "onUpdate:modelValue": [($event: any) => ((_unref(allConfigs)[opt.value][conf.key]) = $event), () => updateSelectValue(opt.value, conf.key)],
                                                    "allow-empty": false,
                                                    "as-number": _ctx.layout[idx + 1].asColType === 'number',
                                                    "inherit-read-only": false,
                                                    disabled: disableInput(opt.value)
                                                  }), null, 16, ["modelValue", "onUpdate:modelValue", "as-number", "disabled"])
                                                ])
                                              ]),
                                              _: 2
                                            }, 1032, ["at-horizontal-center"]))
                                          : (_openBlock(), _createBlock(CmcText, _mergeProps({
                                              key: 2,
                                              ref_for: true
                                            }, conf.options[0], {
                                              text: conf.options[0].label,
                                              "with-i18n": conf.options[0].withLabelI18n
                                            }), null, 16, ["text", "with-i18n"]))
                                    ]),
                                    _: 2
                                  }, 1032, ["padding-horizontal", "padding-right", "padding-left"])
                                ]),
                                _: 2
                              }, 1032, ["as-list-select-item-without-label"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["value", "checked", "onOnclick"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["layout", "disabled", "model-value", "paginable", "with-number-of-records"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["id", "label", "model-value", "read-only", "inherit-read-only", "hide"])
    ]),
    _: 1
  }))
}
}

})